import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Input, Card } from "antd";

import SideBar from "../../components/SideBar";
import { addRH } from "./../../api/data/RH"

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function NewResponsable() {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        grade: "",
        fonction: "",
        cin: "",
        ppr: "",
        phone: "",
        userName: "",
        email: "",
        password: "",
        confirmPass: "",
        permisNumber: ""
    })

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleFormSubmit = async e => {
        e.preventDefault()
        try {
            const response = await addRH(formData.firstName, formData.lastName, formData.grade, formData.fonction, formData.cin, formData.ppr, formData.phone, formData.userName, formData.email, formData.password, formData.confirmPass, formData.permisNumber)

            if(!response) {
                return Swal.fire({
                    title: 'Erreur!',
                    text: 'Server error',
                    icon: 'error'
                });
            }

            Swal.fire({
                title: 'Ajoutée',
                text: '',
                icon: 'success'
            });

            navigate('/admin/responsableTable')
        }
        catch(err) {
            Swal.fire({
                title: 'Erreur!',
                text: err.response.data.error,
                icon: 'error'
            });
        }
    }

  return (
    <section className='NewResponsableSection'>
        <Row>
            <Col md="2" className='p-0'>
                <SideBar />
            </Col>
            <Col md="10" className='p-0'>
                <div className="contents">
                    <Card style={{ width: "60%" }}>
                        <div className="inputContents">
                            <Input placeholder="Nom" onChange={handleInputChange} name='firstName' value={formData.firstName} />
                            <Input placeholder="Prénom" onChange={handleInputChange} name='lastName' value={formData.lastName} />
                            <Input placeholder="Grade" onChange={handleInputChange} name='grade' value={formData.grade} />
                            <Input placeholder="Fonction" onChange={handleInputChange} name='fonction' value={formData.fonction} />
                            <Input placeholder="CIN" onChange={handleInputChange} name='cin' value={formData.cin} />
                            <Input placeholder="PPR" onChange={handleInputChange} name='ppr' value={formData.ppr} />
                            <Input placeholder="Email" onChange={handleInputChange} name='email' value={formData.email} />
                            <Input placeholder="Télèphone" onChange={handleInputChange} name='phone' value={formData.phone} />
                            <Input placeholder="Nom d'utilisateur" onChange={handleInputChange} name='userName' value={formData.userName} />
                            <Input placeholder="Mot de passe" type='password' onChange={handleInputChange} name='password' value={formData.password} />
                            <Input placeholder="confirmer le mot de passe" type='password' onChange={handleInputChange} name='confirmPass' value={formData.confirmPass} />
                            <Input placeholder="Permis numéro" onChange={handleInputChange} name='permisNumber' value={formData.permisNumber} />
                        </div>
                        <div className="submitBtn">
                            <Button onClick={handleFormSubmit}>Valider</Button>
                        </div>
                    </Card>
                </div>
            </Col>
        </Row>
    </section>
  )
}

export default NewResponsable
