import React, { useContext, useEffect, useState } from 'react'
import {Form, Select, DatePicker} from 'antd'

import { getAllCityApi } from '../../api/city'
import {
    listOfResponsable,
    getAllVehicul
} from "../../api/data/Vehiculs";

// import { getAllRH } from '../../api/data/RH'
// import { getAllVehicul } from '../../api/data/Vehiculs'

import { Button } from 'reactstrap'
import { Link } from 'react-router-dom';

import { HeaderContext } from './../../context/headerContext'

const { Option } = Select;
const { RangePicker } = DatePicker;

function Header() {
    const { setSelectedCitiesContext, setSelectedDateContext, setSelectedFiltersContext } = useContext(HeaderContext);

    const [cityData, setCityData] = useState([])
    const [vehiculesData, setVehiculesData] = useState([])
    const [responsablesData, setResponsablesData] = useState([])
    const [selectedCities, setSelectedCities] = useState({
        startCityName: '',
        arrivedCityName: ''
    })
    const [selectedFilters, setSelectedFilters] = useState({
        selectedModele: '',
        selectedConducteur: ''
    })

    useEffect(() => {
        getAllInitialData()
        // eslint-disable-next-line
    }, [])

    const getAllInitialData = async () => {
        const vehicules = await getAllVehicul()
        setVehiculesData(vehicules)
        const responsables = await listOfResponsable()
        setResponsablesData(responsables)
        const cityRes = await getAllCityApi()
        setCityData(cityRes)
    }

    const handleFilterChange = async (value, key) => {
        if(key) {
            setSelectedFilters({...selectedFilters, [key?.key?.split('_')[0]]: value})
            setSelectedFiltersContext({...selectedFilters, [key?.key?.split('_')[0]]: value})
        }
        if(!key) {
            setSelectedFilters({
                selectedModele: '',
                selectedConducteur: ''
            })
            setSelectedFiltersContext({
                selectedModele: 'All',
                selectedConducteur: 'All'
            })
        }
    }

    const handleSelectChange = (value, key) => {
        if(key) {
            setSelectedCities({...selectedCities, [key?.key?.split('_')[0]]: value})
            setSelectedCitiesContext({...selectedCities, [key?.key?.split('_')[0]]: value})
        }
        if(!key) {
            setSelectedCities({
                startCityName: '',
                arrivedCityName: ''
            })
            setSelectedCitiesContext({
                startCityName: 'All',
                arrivedCityName: 'All'
            })
        }
    }
    const handleDateChange = (value, date) => {
        setSelectedDateContext({
            SelectedFromDate: date[0],
            SelectedToDate: date[1]
        })
    }

  return (
    <section className='headerComponent'>
        <div className='contents'>
            <div className='rowContents'>
                <p>Gestion des opérations</p>
            </div>
            <div className='rowContents'>
                <Select
                    showSearch
                    allowClear
                    placeholder="Modèle"
                    optionFilterProp="children"
                    onChange={handleFilterChange}
                    // filterOption={(input, option) =>
                    //     option?.key?.split('_')[1]?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    // }
                    style={{ width: '100%' }}
                >
                    {vehiculesData.map((el) => (
                        <Option key={`selectedModele_${el._id}`} value={el._id}>{el.marque}</Option>
                    ))}
                </Select>
            </div>
            <div className='rowContents'>
                <Select
                    showSearch
                    allowClear
                    placeholder="Conducteur"
                    optionFilterProp="children"
                    onChange={handleFilterChange}
                    // filterOption={(input, option) =>
                    //     option?.key?.split('_')[1]?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    // }
                    style={{ width: '100%' }}
                >
                    {responsablesData.map((el) => (
                        <Option key={`selectedConducteur_${el._id}`} value={el._id}>{el.firstName} {el.lastName}</Option>
                    ))}
                </Select>
            </div>

            <div className='rowContents'>
                <Select
                    showSearch
                    allowClear
                    placeholder="Ville de départ"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                        option?.key?.split('_')[1]?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                >
                    {cityData.map((el) => (
                        <Option key={`startCityName_${el.ville}`} value={el.ville}>{el.ville}</Option>
                    ))}
                </Select>
            </div>
            <div className='rowContents'>
                <Select
                    showSearch
                    allowClear
                    placeholder="Ville d'arrivée"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                        option.key.split('_')[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: '100%' }}
                >
                    {cityData.map((el) => (
                        <Option key={`arrivedCityName_${el.ville}`} value={el.ville}>{el.ville}</Option>
                    ))}
                </Select>
            </div>
            <div className='rowContents'>
                <Form.Item name="range-picker">
                    <RangePicker onChange={handleDateChange} />
                </Form.Item>
            </div>
            <div className='rowContents'>
                <Link to="/admin/NewGestionOP">
                    <Button className='submitButton'> Créer un envoi </Button>
                </Link>
            </div>
        </div>
    </section>
  )
}

export default Header
