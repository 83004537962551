import axios from "axios";

export const getGeoLocationApi = async(address) => {
    try {
        const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyDy12u4o5Aa4iAxRxXxsWzBm8VvkLxgFUQ`)
        return res.data
    }
    catch(err) {
        console.log(err)
        throw err
    }
}
