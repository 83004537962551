import React, { Fragment } from "react";
import Header from "./../../components/itemsManagement/Header";
import SideBar from "../../components/SideBar";
import { Row, Col, Table, Button } from "reactstrap";
import { AiFillDelete } from "react-icons/ai"
// AiFillDelete

function ItemsManagement() {
  return (
    <Fragment>
      <section className="itemsManagementSection">
        <div className="contents">
          <Row>
            <Col md="2" className="p-0">
              <SideBar />
            </Col>
            <Col md="10" className="p-0">
              <Header />

              <div className="table">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Numéro d'article</th>
                      <th>Parc</th>
                      <th>Date</th>
                      <th>Désignation</th>
                      <th>Véhicule</th>
                      <th>Prix</th>
                      <th>État d'opération</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                      <td>@mdo</td>
                      <td>@mdo</td>
                      <td>@mdo</td>
                      <td>
                        <div className="btnStatus">
                          <Button size="sm" color="success">Valider</Button>
                          <Button size="sm" color="danger">Annuler</Button>
                          <Button size="sm" color="warning">En cours</Button>
                        </div>
                      </td>
                      <td>
                        <AiFillDelete size="1.5rem" color="red" />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
}

export default ItemsManagement;
