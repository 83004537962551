import API_URL from "../../config";
import axios from "axios";

export const addVehicul = async (marque, modele, miseEnCirculation, immatriculation, responsable, cartGris, prefectorale) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        // if(!token || !id)
        //     return false

        const VehiculObject = {
            marque: marque,
            modele: modele,
            miseEnCirculation: miseEnCirculation,
            immatriculation: immatriculation,
            responsable: responsable,
            cartGris: cartGris,
            prefectorale: prefectorale
        };

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/flotte/add/${id}`, VehiculObject, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getOneVehicul = async (VehiculID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/flotte/getOne/${id}/${VehiculID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllVehicul = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/flotte/getAll/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const updateVehiculStatus = async (VehiculID, VehiculStatus) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/flotte/updateOneStatus/${id}/${VehiculID}`, {status: VehiculStatus}, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const deleteOneVehicul = async (VehiculID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/flotte/delete/${id}/${VehiculID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const listOfMatricul = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/documents/getAllDocName/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const listOfResponsable = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/humanRessources/getAllRHName/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}
