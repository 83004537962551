import { createContext, useState } from 'react';

export const HeaderContext = createContext();

export const HeaderProvider = ({ children }) => {
    const [selectedFiltersContext, setSelectedFiltersContext] = useState({
      selectedModele: 'All',
      selectedConducteur: 'All'
    })
    const [selectedCitiesContext, setSelectedCitiesContext] = useState({
        startCityName: 'All',
        arrivedCityName: 'All'
    })
    const [selectedDateContext, setSelectedDateContext] = useState({
        SelectedFromDate: 'All',
        SelectedToDate: 'All'
    })

  return (
    <HeaderContext.Provider value={{ selectedCitiesContext, setSelectedCitiesContext, selectedDateContext, setSelectedDateContext, selectedFiltersContext, setSelectedFiltersContext }}>
      {children}
    </HeaderContext.Provider>
  );
};
