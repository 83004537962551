import React, { Fragment } from 'react'
import { useLocation, Route, Routes, Navigate } from "react-router-dom";

import routes from "./../routes";

function Auth() {
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        if(mainContent.current) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            mainContent.current.scrollTop = 0;
        }
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
            return (
                <Route path={prop.path} element={prop.component} key={key} exact />
            );
            } else {
            return null;
            }
        });
    };

  return (
    <Fragment>
        <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
    </Fragment>
  )
}

export default Auth
