import API_URL from '../config';
import axios from 'axios';

export const addVariableLoad = async (document, date, immatriculation, model, typeOfData) => {
    try {
        const formData = new FormData()

        formData.append('document', document)
        formData.append('date', date)
        formData.append('immatriculation', immatriculation)
        formData.append('model', model)

        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`,
              'content-type': 'multipart/form-data'
            }
        }

        const thisYear = new Date().getFullYear()

        const res = await axios.post(`${API_URL}/api/chargeVariable/${typeOfData}/${thisYear}/${id}`, formData, option)
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const addJsonVariableLoad = async (annualBudget, date, immatriculation, model, carTiresBudget, typeOfData) => {
    try {
        let objectData
        if(typeOfData === 'Pneumatiques') {
            objectData = {
                annualBudget: annualBudget,
                carTiresBudget: carTiresBudget,
            }
        }
        else {
            objectData = {
                annualBudget: annualBudget,
            }
        }

        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const thisYear = new Date().getFullYear()

        const res = await axios.post(`${API_URL}/api/chargeVariable/jsonData/${typeOfData}/${thisYear}/${id}`, objectData, option)
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const countVariableLoad = async (typeOfData) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const thisYear = new Date().getFullYear()

        const res = await axios.get(`${API_URL}/api/chargeVariable/count/${typeOfData}/${thisYear}/${id}`, option)
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneVariableLoad = async (typeOfData) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const thisYear = new Date().getFullYear()

        const res = await axios.get(`${API_URL}/api/chargeVariable/getOne/${typeOfData}/${thisYear}/${id}`, option)
        return res.data
    }
    catch (err) {
        throw err
    }
}
