import React, { Fragment } from 'react'

import { Row, Col } from 'reactstrap'
import SideBar from '../../components/SideBar'

import BarChart from '../../components/Home/Chart/BarChart.js'
import Bar from '../../components/Home/Chart/Bar.js'
import LineChart from '../../components/Home/Chart/LineChart.js'
import PieChart from '../../components/Home/Chart/PieChart.js'

function Home() {
  return (
    <Fragment>
        <section className='Home_page'>
            <div className="contents">
                <Row>
                    <Col md="2" className='p-0'>
                        <SideBar />
                    </Col>
                    <Col md="10" className='p-0'>
                        <Row>
                            <Col md="6">
                                <BarChart />
                            </Col>
                            <Col md="6">
                                <Bar />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <LineChart />
                            </Col>
                            <Col md="6">
                                <PieChart />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </section>
    </Fragment>
  )
}

export default Home
