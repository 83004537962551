import React from 'react'
import { Col, Row } from 'reactstrap'
import SideBar from '../../components/SideBar'
import { FaUserLarge, FaTruckField, FaFile } from 'react-icons/fa6'
import { Card } from 'antd'
import { Link } from 'react-router-dom'

import Header from '../../components/DataManagement/Header'

function DataManagement() {
  return (
    <section className='dataManagement'>
        <Row>
            <Col md="2" className="p-0">
                <SideBar />
            </Col>
            <Col md="10" className="p-0">
                <Header />

                <div className="contents">
                    <Card style={{width: '70%'}}>
                        <div className="icones">
                            <div className="icon">
                                <Link to="/admin/responsableTable">
                                    <FaUserLarge />
                                </Link>
                                <p>Ressources Humaines</p>
                            </div>
                            <div className="icon">
                                <Link to="/admin/floatTable">
                                    <FaTruckField />
                                </Link>
                                <p>Flotte</p>
                            </div>
                            <div className="icon">
                                <Link to="/admin/documentTable">
                                    <FaFile />
                                </Link>
                                <p>Documents</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </Col>
        </Row>
    </section>
  )
}

export default DataManagement
