import React, { Fragment, useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button, CardBody } from "reactstrap";

import { getAllCurrentOperationApi } from "../../api/operationManagemment";

import { HeaderContext } from './../../context/headerContext'
import { useNavigate } from "react-router-dom";

function CustomTabContent2({ orderDirection }) {
  const navigate = useNavigate()

  const { selectedCitiesContext, selectedDateContext, selectedFiltersContext } = useContext(HeaderContext);

  const [InitialData, setInitialData] = useState([]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line
  }, [selectedCitiesContext, selectedDateContext, selectedFiltersContext]);

  const getInitialData = async () => {
    try {
      const response = await getAllCurrentOperationApi(selectedCitiesContext.startCityName, selectedCitiesContext.arrivedCityName, selectedDateContext.SelectedFromDate, selectedDateContext.SelectedToDate,  selectedFiltersContext.selectedModele, selectedFiltersContext.selectedConducteur);
      setInitialData(response);
    } catch (err) {}
  };

  const handleCardClick = (from, to) => {
    const startPosition = {
        lat: from.lat,
        lng: from.lng
    }
    const endPosition = {
        lat: to.lat,
        lng: to.lng
    }

    const direction = {startPosition: startPosition, endPosition: endPosition}
    orderDirection(direction)
  }

  const handleEditeReddirection = (ID) => {
    navigate("/admin/EditeGestionOP/" + ID);
  }

  const handleDownloadMissionOrder = (orderID) => {
    navigate("/admin/missionOrder/" + orderID);
  }

  return (
    <Fragment>
      <section className="leftSideTabContent">
        {InitialData.map((el) => (
          <Card className="mb-2 cardStyle" onClick={() => handleCardClick(el.startCityLocation, el.arrivedCityLocation)}>
            <CardBody>
              <Row>
                <Col md="4">
                  <div className="fromTo">
                    <p>
                      <b>{el.startCityName}</b>{" "}
                      {new Date(el.SelectedFromDate)
                        .toLocaleString()
                        .substr(10)}{" "}
                    </p>
                    <small>
                      {new Date(el.SelectedFromDate)
                        .toLocaleString()
                        .substr(0, 10)}
                    </small>

                    <div className="space"></div>

                    <p>
                      <b>{el.arrivedCityName}</b>{" "}
                      {new Date(el.SelectedToDate).toLocaleString().substr(10)}{" "}
                    </p>
                    <small>
                      {new Date(el.SelectedToDate)
                        .toLocaleString()
                        .substr(0, 10)}
                    </small>
                  </div>
                </Col>
                <Col md="4">
                  <div className="modeleAndConductor">
                    <h3>
                      Modèle <br /> <span>{el.selectedModel.marque}</span>
                    </h3>
                    <div className="space"></div>
                    <h4>
                      Conducteur <br />{" "}
                      <span>
                        {el.selectedConducteur.firstName}{" "}
                        {el.selectedConducteur.lastName}
                      </span>
                    </h4>
                    <p>{el.selectedConducteur.phone}</p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="handleOrderMission">
                    <p>{el.selectedMatricule.immatriculation}</p>
                    <img
                      src={require("./../../assets/img/operationM/conductor.png")}
                      alt=""
                    />
                    <Button onClick={() => handleDownloadMissionOrder(el._id)}>Télécharger un ordre de mission</Button>
                    <Button onClick={() => handleEditeReddirection(el._id)}>Modifier</Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </section>
    </Fragment>
  )
}

export default CustomTabContent2
