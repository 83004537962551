import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.25 82.9" width={size} height={size}>
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <g>
        <path class="cls-1" fill={fill} d="M33.15,72.69h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
        <path class="cls-1" fill={fill} d="M106.74,72.69h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
        <path class="cls-1" fill={fill} d="M33.15,72.69c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
        <path class="cls-1" fill={fill} d="M33.15,72.69c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
        <path class="cls-1" fill={fill} d="M106.74,72.69c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
        <path class="cls-1" fill={fill} d="M106.74,72.69c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
        <rect class="cls-1" fill={fill} x="34.49" y="73.74" width="52.34" height="2.55"/>
        <path class="cls-1" fill={fill} d="M113.27,56.27c-1.85-1.85-4.42-2.57-7.21-2.99-2.34-.36-7.54-1.44-8.6-2.09l-3-3.86c-3.59-5.51-8.27-10.21-13.75-13.84l-.35-.23c-1.9-1.26-4.14-1.93-6.42-1.93H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM3.38,66.56h0c-.46,0-.83-.37-.83-.83v-16.72c0-.46,.37-.83,.83-.83h0c.46,0,.83,.37,.83,.83v16.72c0,.46-.37,.83-.83,.83Zm66.83-18.38v-12.39h8.78c1.63,0,3.17,.75,4.17,2.03l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.59,4.2c.21,.34-.03,.77-.43,.77h-18.89Z"/>
      </g>
      <path class="cls-1" fill={fill} d="M99,9.25v8.71c0,3.13-1.18,7.95-2.63,10.73-.96,1.85-2.07,3.65-3.3,5.35l-3.07,4.23-1.78-1.78,2.82-3.91c1.16-1.6,2.2-3.3,3.11-5.04,1.27-2.45,2.35-6.83,2.35-9.58V9.25c0-.77-.63-1.4-1.4-1.4h-4.4c-.94,0-1.86-.25-2.67-.71l-7.89-4.57c-.14-.09-.32-.09-.46,0l-7.89,4.58c-.81,.46-1.74,.71-2.67,.71h-4.4c-.77,0-1.4,.63-1.4,1.4v7.06c0,3.21,1.25,8.32,2.73,11.16l1.28,2.45h-2.82l-.68-1.3c-1.66-3.19-3.01-8.71-3.01-12.31v-7.06c0-2.15,1.75-3.9,3.9-3.9h4.4c.49,0,.99-.13,1.42-.38L78.42,.4c.92-.53,2.06-.53,2.97,0l7.89,4.57c.43,.25,.92,.38,1.42,.38h4.4c2.15,0,3.9,1.75,3.9,3.9Z"/>
      <path class="cls-1" fill={fill} d="M90.97,19.62c0,6.1-4.97,11.06-11.07,11.06s-11.05-4.96-11.05-11.06c0-1.81,.45-3.61,1.3-5.2l.93,1.54c-.49,1.15-.73,2.4-.73,3.66,0,5.27,4.28,9.56,9.55,9.56s9.57-4.29,9.57-9.56-4.29-9.56-9.57-9.56c-2.41,0-4.66,.87-6.41,2.47l-1.06-1.06c2.02-1.86,4.7-2.91,7.47-2.91,6.1,0,11.07,4.96,11.07,11.06Z"/>
      <path class="cls-1" fill={fill} d="M69.71,11.2c3.56,3.26,7.13,6.85,10.56,10.23,0,0-2.19-.13-2.19-.13,1.71-1.53,3.69-2.81,5.9-3.56,0,0,.2,.22,.2,.22-1.12,2.64-3.09,4.82-5.21,6.68-1.81-2.07-3.61-4.98-5.3-7.18-1.42-1.99-2.81-4.02-4.17-6.06,0,0,.22-.19,.22-.19h0Z"/>
      <line class="cls-2" fill="none" x1="90" y1="38.27" x2="88.22" y2="36.49"/>
      <polyline class="cls-2" fill="none" points="71.08 15.96 70.15 14.42 70.15 14.41"/>
      <line class="cls-2" fill="none" x1="73.49" y1="12.53" x2="72.43" y2="11.47"/>
    </g>
  </g>
</svg>
  );
}

export default Assurances;
