import React, { Fragment, useEffect, useState } from "react";
import { Tabs } from "antd";

import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { Row, Col } from "reactstrap";
import SideBar from "../../components/SideBar";
import Header from "../../components/OperationManagement/Header";

import CustomTabContent from "../../components/OperationManagement/CustomTabContent";
import CustomTabContent2 from "../../components/OperationManagement/CustomTabContent2";
import CustomTabContent3 from "../../components/OperationManagement/CustomTabContent3";

import MapComponent from "../../components/OperationManagement/MapComponent";

import { countAllPlanifiedOperationApi, countAllCurrentOperationApi, countAllFinishedOperationApi } from "./../../api/operationManagemment"

function OperationManagement() {
  const position = [30.379245, -9.520939];

  // eslint-disable-next-line
  const [startPosition, setStartPosition] = useState({
    // lat: 33.036731,
    // lng: -7.484337
  })
  // eslint-disable-next-line
  const [endPosition, setEndPosition] = useState({
    // lat: 30.410664,
    // lng: -9.584274
  })

  const [countedPlanifiedData, setCountedPlanifiedData] = useState([])
  const [countedCurrentData, setCountedCurrentData] = useState([])
  const [countedFinishedData, setCountedFinishedData] = useState([])

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const P = await countAllPlanifiedOperationApi()
      const E = await countAllCurrentOperationApi()
      const T = await countAllFinishedOperationApi()

      setCountedPlanifiedData(P)
      setCountedCurrentData(E)
      setCountedFinishedData(T)
    }
    catch(err) {

    }
  }

  const onChange = (key) => {
    console.log(key);
  };

  const handleShowOrderDirection = (data) => {
    setStartPosition({})
    setEndPosition({})

    setStartPosition(data.startPosition)
    setEndPosition(data.endPosition)
  }

  const tabStyles = {
    width: "12rem", // Adjust the width as needed
  };

  const tabsData = [
    {
      label: (
        <div style={tabStyles}>
          <b>Les trajets Plannifiés</b>
          <br />
          <p>{countedPlanifiedData.numberOfOp} {parseInt(countedPlanifiedData.numberOfOp) >= 2 ? 'opérations' : 'opération'}, {countedPlanifiedData.numberOfVehicul} {parseInt(countedPlanifiedData.numberOfVehicul) >= 2 ? 'Véhicules' : 'Véhicule' } </p>
        </div>
      ),
      key: 1,
      children: <CustomTabContent orderDirection={handleShowOrderDirection} />,
    },
    {
      label: (
        <div style={tabStyles}>
          <b>Les trajets en Cours</b>
          <br />
          <p>{countedCurrentData.numberOfOp} {parseInt(countedCurrentData.numberOfOp) >= 2 ? 'opérations' : 'opération'}, {countedCurrentData.numberOfVehicul} {parseInt(countedCurrentData.numberOfVehicul) >= 2 ? 'Véhicules' : 'Véhicule' }</p>
        </div>
      ),
      key: 2,
      children: <CustomTabContent2 orderDirection={handleShowOrderDirection} />,
    },
    {
      label: (
        <div style={tabStyles}>
          <b>Les trajets Terminés</b>
          <br />
          <p>{countedFinishedData.numberOfOp} {parseInt(countedFinishedData.numberOfOp) >= 2 ? 'opérations' : 'opération'}, {countedFinishedData.numberOfVehicul} {parseInt(countedFinishedData.numberOfVehicul) >= 2 ? 'Véhicules' : 'Véhicule' }</p>
        </div>
      ),
      key: 3,
      children: <CustomTabContent3 orderDirection={handleShowOrderDirection} />,
    },
  ];

  return (
    <Fragment>
      <section className="OperationManagementSection">
        <div className="contents">
          <Row>
            <Col md="2" className="p-0">
              <SideBar />
            </Col>
            <Col md="10" className="p-0">
              <Header />
              <Row>
                <Col md="8" className="p-0">
                  <Tabs onChange={onChange} type="card" items={tabsData} />
                </Col>
                <Col md="4" className="p-0">
                  <div className="rightSideTabContent">
                    <MapContainer
                      center={position}
                      zoom={13}
                      style={{ height: "77vh" }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <MapComponent
                        startPoint={startPosition}
                        endPoint={endPosition}
                      />
                    </MapContainer>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    </Fragment>
  );
}

export default OperationManagement;

// https://www.google.com/maps/dir/30.368305,-9.528072/30.377414,-9.506786/@30.368305,-9.528072,10z
