import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.98 93.93" width={size} height={size}>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-7" fill={fill} d="M110.03,46.57l34.28-22.77c.78-.52,.89-1.62,.23-2.29l-3.07-3.07c-.5-.5-1.27-.58-1.86-.19l-31.22,20.31c-.42,.27-.68,.75-.68,1.25v5.53c.02,1.18,1.34,1.89,2.32,1.23Z"/>
          <polygon class="cls-1" fill="#FFFFFF" strokeMiterlimit="10" stroke={fill} points="109.2 41.25 109.2 44.38 113.99 40.93 113.99 38.03 109.2 41.25"/>
          <polygon class="cls-1" fill="#FFFFFF" strokeMiterlimit="10" stroke={fill} points="115.52 36.66 115.52 39.79 120.3 36.34 120.3 33.44 115.52 36.66"/>
          <polygon class="cls-1" fill="#FFFFFF" strokeMiterlimit="10" stroke={fill} points="121.98 32.45 121.98 35.57 126.77 32.13 126.77 29.22 121.98 32.45"/>
          <polygon class="cls-1" fill="#FFFFFF" strokeMiterlimit="10" stroke={fill} points="128.44 28.23 128.44 31.36 133.23 27.91 133.23 25.01 128.44 28.23"/>
          <polygon class="cls-1" fill="#FFFFFF" strokeMiterlimit="10" stroke={fill} points="134.9 24.02 134.9 27.15 139.69 23.7 139.69 20.8 134.9 24.02"/>
          <polygon class="cls-2" fill="#FFFFFF" points="140.63 20.38 140.6 23 142.71 21.86 140.63 20.38"/>
          <g>
            <path class="cls-6" fill={fill} d="M33.15,83.72h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-6" fill={fill} d="M106.74,83.72h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-6" fill={fill} d="M33.15,83.72c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-6" fill={fill} d="M33.15,83.72c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-6" fill={fill} d="M106.74,83.72c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <path class="cls-6" fill={fill} d="M106.74,83.72c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <rect class="cls-6" fill={fill} x="34.49" y="84.77" width="52.34" height="2.55"/>
            <path class="cls-6" fill={fill} d="M113.27,67.3c-1.85-1.85-4.42-2.57-7.21-2.99-2.34-.36-7.54-1.44-8.6-2.09l-3-3.86c-3.59-5.51-8.27-10.21-13.75-13.84l-.35-.23c-1.9-1.26-4.14-1.93-6.42-1.93H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM3.38,77.59h0c-.46,0-.83-.37-.83-.83v-16.72c0-.46,.37-.83,.83-.83h0c.46,0,.83,.37,.83,.83v16.72c0,.46-.37,.83-.83,.83Zm66.83-18.38v-12.39h8.78c1.63,0,3.17,.75,4.17,2.03l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.59,4.2c.21,.34-.03,.77-.43,.77h-18.89Z"/>
          </g>
          <polygon class="cls-3" fill="#FFFFFF" strokeWidth="2.5px" strokeLinejoin="round" stroke={fill} strokeLinecap="round" points="107.83 12.57 78.9 12.57 93.37 1.25 107.83 12.57"/>
          <polyline class="cls-4" fill="#FFFFFF" strokeWidth="2.5px" strokeLinejoin="round" stroke={fill} points="107.83 63.04 107.83 12.57 78.9 12.57 78.9 40.31"/>
          <rect class="cls-5" fill="#FFFFFF" stroke={fill} strokeWidth="2.5px" strokeMiterlimit="10" x="85.54" y="19.97" width="15.06" height="12.85"/>
        </g>
      </g>
    </svg>
  );
}

export default Assurances;
