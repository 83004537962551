import React, { Fragment } from "react";
import { Button, Col, Row } from "reactstrap";
import SideBar from "../../components/SideBar";

import axios from 'axios';

import Assurances from "./../../assets/icones/Assurances";
import AutreCout from "./../../assets/icones/AutreCout";
import Carburant from "./../../assets/icones/Carburant";
// import CoutDeStructure from "./../../assets/icones/CoutDeStructure";
import Deplacement from "./../../assets/icones/Deplacement";
import Entretien from "./../../assets/icones/Entretien";
// import ExploitationAutre from "./../../assets/icones/ExploitationAutre";
import Peages from "./../../assets/icones/Peages";
import Pneumatiques from "./../../assets/icones/Pneumatiques";
import Taxes from "./../../assets/icones/Taxes";
import { Input, DatePicker, Select } from "antd";
import { useState, useEffect } from "react";

import { BsFileEarmarkArrowDown } from 'react-icons/bs'

import { addVariableLoad, addJsonVariableLoad, countVariableLoad, getOneVariableLoad } from "./../../api/variableLoad";

import { listOfMatricul, getAllVehicul } from "../../api/data/Vehiculs";

import API_URL from "../../config";

import dayjs from 'dayjs';

import Swal from "sweetalert2";
const { Option } = Select;

function ChargeVariable() {
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFilePath, setSelectedFilePath] = useState(null)

  const [selectedChargeDate, setSelectedChargeDate] = useState(null);
  const [selectedChargeDate2, setSelectedChargeDate2] = useState(null);

  const [vehiculData, setVehiculData] = useState([]);
  const [matriculData, setMatriculData] = useState([]);

  const [numberOfVariableLoad, setNumberOfVariableLoad] = useState([])

  const [formValues, setFormValues] = useState({
    immatriculation: "",
    model: "",
    annualBudget: 0,

    carTiresPrice: 0,
    carTiresQuantity: 0,
    carTiresDuration: "",
  });

  useEffect(() => {
    getAllInitialData();
  }, []);

  const dateFormat = 'YYYY/MM/DD';

  const getAllInitialData = async () => {
    const matriculDataRes = await listOfMatricul();
    setMatriculData(matriculDataRes);
    const vehiculDataRes = await getAllVehicul();
    setVehiculData(vehiculDataRes);
  };

  const fileToBlob = async (fileObject) => {
    const { blob, fileName, mimeType, filePath } = fileObject;

    const options = {
      type: mimeType || 'application/octet-stream',  // Default MIME type if not provided
      lastModified: new Date().getTime(),
      lastModifiedDate: new Date(),
    };
  
    // Create a custom object to hold the file and metadata
    const file = new File([blob], fileName, options);
    file.filePath = filePath;  // Simulated file path
  
    return file;
  }

  const handleSelectedCharge = async (selectedCharge) => {
    setFormValues({
      immatriculation: "",
      model: "",
      annualBudget: 0,

      carTiresPrice: 0,
      carTiresQuantity: 0,
      carTiresDuration: "",
    })

    setSelectedFile(null);
    setSelectedFilePath(null)
    setSelectedCharge(selectedCharge);

    const countVariableL = await countVariableLoad(selectedCharge)
    setNumberOfVariableLoad(countVariableL)

    const oneVariableL = await getOneVariableLoad(selectedCharge)

    if(oneVariableL && selectedCharge === 'Assurances' || selectedCharge === 'Taxes') {
      setFormValues({
        ...formValues,
        immatriculation: oneVariableL?.immatriculation,
        model: oneVariableL?.model
      })

      setSelectedChargeDate(dayjs(oneVariableL?.date).format(dateFormat));
      setSelectedChargeDate2(dayjs(oneVariableL?.date).format(dateFormat));

      setSelectedFilePath(oneVariableL?.document)

      if(oneVariableL?.document && oneVariableL?.document != "") {
        // const blob = await fileToBlob(`${API_URL}/${oneVariableL?.document}`);

        // const blob = new Blob(['Blob content'], { type: 'application/pdf' });
        // const fileName = `${selectedCharge === 'Assurances' ? 'Fichier Assurances.pdf' : 'Fichier Taxes par véhicule.pdf'}`;
        // const filePath = `${API_URL}/${oneVariableL?.document}`;

        // const fileObject = await fileToBlob(blob, fileName, filePath);
        const fileObject = {
          blob: new Blob(['Blob content'], { type: 'application/pdf' }),
          fileName: `${selectedCharge === 'Assurances' ? 'Fichier Assurances.pdf' : 'Fichier Taxes par véhicule.pdf'}`,
          mimeType: 'application/pdf',
          filePath: `${API_URL}/${oneVariableL?.document}`
        };

        const file = await fileToBlob(fileObject);
        setSelectedFile(file)
      }
    }

    else if(oneVariableL && countVariableL && countVariableL !== 0) {
      if(selectedCharge === "Pneumatiques") {
        setFormValues({
          ...formValues,
          annualBudget: oneVariableL?.annualBudget,

          carTiresPrice: oneVariableL?.carTiresBudget.price,
          carTiresQuantity: oneVariableL?.carTiresBudget.quantity,
          carTiresDuration: oneVariableL?.carTiresBudget.duration
        })
      }
      else {
        setFormValues({
          ...formValues,
          annualBudget: oneVariableL?.annualBudget
        })
      }
    }
  };
  const handleDateChange1 = (date, dateString) => {
    setSelectedChargeDate(dateString);
  };
  const handleDateChange2 = (date, dateString) => {
    setSelectedChargeDate2(dateString);
  };
  const handleAssuranceFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const handleUploadedFileDownload = () => {
    window.open(`${API_URL}/${selectedFilePath}`, '_blank')
  }
  const handleSelectionChange = (value, name) => {
    if (name.key === "model") {
      const selectedVehicul = vehiculData.find((el) => el._id === value);

      setFormValues({
        ...formValues,
        immatriculation: selectedVehicul.immatriculation._id,
        model: selectedVehicul._id,
      });
    } else {
      setFormValues({
        ...formValues,
        [name.key]: value,
      });
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleAddVariableLoad = async (typeOf) => {
    try {
      const resetFormData = () => {
        setFormValues({
          immatriculation: "",
          model: "",
          annualBudget: 0,

          carTiresPrice: 0,
          carTiresQuantity: 0,
          carTiresDuration: "",
        });
        setSelectedFile(null);
        setSelectedCharge(null);

        setSelectedChargeDate(null)
        setSelectedChargeDate2(null)
      };

      if (typeOf === "Assurances" || typeOf === "Taxes") {
        if (formValues.immatriculation === "" || formValues.model === "") {
          return Swal.fire({
            title: "Erreur!",
            text: "Selectionner Modèle et Immatriculation",
            icon: "error",
          });
        } else if (!selectedChargeDate || selectedChargeDate === "") {
          return Swal.fire({
            title: "Erreur!",
            text: "Insérer la date",
            icon: "error",
          });
        }

        const response = await addVariableLoad(
          selectedFile,
          selectedChargeDate,
          formValues.immatriculation,
          formValues.model,
          typeOf
        );

        if (!response) {
          return Swal.fire({
            title: "Erreur!",
            text: "Server error",
            icon: "error",
          });
        }

        Swal.fire({
          title: "Ajoutée",
          text: "",
          icon: "success",
        });

        resetFormData();
      }
      else {
        if(!formValues.annualBudget || formValues.annualBudget === 0) {
          return Swal.fire({
            title: "Erreur!",
            text: "Entrer le budget annuel",
            icon: "error",
          });
        }

        const carTiresBudget = {
          price: parseFloat(formValues.carTiresPrice),
          quantity: parseFloat(formValues.carTiresQuantity),
          duration: parseFloat(formValues.carTiresDuration)
        };

        const response = await addJsonVariableLoad(
          formValues.annualBudget,
          selectedChargeDate,
          formValues.immatriculation,
          formValues.model,
          carTiresBudget,
          typeOf
        );
        if (!response) {
          return Swal.fire({
            title: "Erreur!",
            text: "Server error",
            icon: "error",
          });
        }

        Swal.fire({
          title: "Ajoutée",
          text: "",
          icon: "success",
        });

        resetFormData();
      }
    } catch (err) {
      Swal.fire({
        title: "Erreur!",
        text: err.response.data.error,
        icon: "error",
      });
    }
  };

  return (
    <section className="chargeVariableSection">
      <Row>
        <Col md="2" className="p-0">
          <SideBar />
        </Col>
        <Col md="10" className="p-0">
          <div className="contents">
            <div className="filter">
              {selectedCharge === "Assurances" || selectedCharge === "Taxes" ? (
                <Fragment>
                  {/* {JSON.stringify(formValues)} */}
                  <div className="inputField">
                    <label htmlFor="Modele">Véhicule / Modèle</label>
                    <Select
                      onChange={handleSelectionChange}
                      value={formValues.model}
                      id="selectedModelID"
                    >
                      {vehiculData.map((el) => (
                        <Option key="model" value={el._id}>
                          {el.marque} - {el.modele}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="inputField">
                    <label htmlFor="Immatriculation">
                      Véhicule / Immatriculation
                    </label>
                    <Select
                      onChange={handleSelectionChange}
                      value={formValues.immatriculation}
                      id="Immatriculation"
                    >
                      {matriculData.map((el) => (
                        <Option key="immatriculation" value={el._id}>
                          {el.immatriculation}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Fragment>
              ) : (
                <div className="inputField mt-5"></div>
              )}
            </div>

            <div className="chargeVariableIcons">
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Assurances")}
              >
                <Assurances
                  color={
                    selectedCharge === "Assurances" ? "#1FC607" : "#245776"
                  }
                  size="120px"
                />
                <p
                  className={selectedCharge === "Assurances" ? "selected" : ""}
                >
                  Assurances
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Taxes")}
              >
                <Taxes
                  color={selectedCharge === "Taxes" ? "#1FC607" : "#245776"}
                  size="120px"
                />
                <p className={selectedCharge === "Taxes" ? "selected" : ""}>
                  Taxes par véhicule
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Carburant")}
              >
                <Carburant
                  color={selectedCharge === "Carburant" ? "#1FC607" : "#245776"}
                  size="120px"
                />
                <p className={selectedCharge === "Carburant" ? "selected" : ""}>
                  Carburant
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Pneumatiques")}
              >
                <Pneumatiques
                  color={
                    selectedCharge === "Pneumatiques" ? "#1FC607" : "#245776"
                  }
                  size="120px"
                />
                <p
                  className={
                    selectedCharge === "Pneumatiques" ? "selected" : ""
                  }
                >
                  Pneumatique
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Peages")}
              >
                <Peages
                  color={selectedCharge === "Peages" ? "#1FC607" : "#245776"}
                  size="120px"
                />
                <p className={selectedCharge === "Peages" ? "selected" : ""}>
                  Péage
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Entretien")}
              >
                <Entretien
                  color={selectedCharge === "Entretien" ? "#1FC607" : "#245776"}
                  size="120px"
                />
                <p className={selectedCharge === "Entretien" ? "selected" : ""}>
                  L'entretien-réparation
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("Deplacement")}
              >
                <Deplacement
                  color={
                    selectedCharge === "Deplacement" ? "#1FC607" : "#245776"
                  }
                  size="120px"
                />
                <p
                  className={selectedCharge === "Deplacement" ? "selected" : ""}
                >
                  Indemnités de déplacement
                </p>
              </div>
              <div
                className="icon"
                onClick={() => handleSelectedCharge("AutreCout")}
              >
                <AutreCout
                  color={selectedCharge === "AutreCout" ? "#1FC607" : "#245776"}
                  size="120px"
                />
                <p className={selectedCharge === "AutreCout" ? "selected" : ""}>
                  Autre coût à inclure dans la recomposition annuelle
                </p>
              </div>
            </div>

            {/* <div className="icon" onClick={() => handleSelectedCharge('CoutDeStructure')}>
              <CoutDeStructure color={selectedCharge === 'CoutDeStructure' ? '#1FC607' : '#245776'} size="120px" />
              <p className={selectedCharge === 'CoutDeStructure' ? 'selected' : ''}>Cout de structure et autres charges</p>
            </div> */}
            {/* <div className="icon" onClick={() => handleSelectedCharge('ExploitationAutre')}>
              <ExploitationAutre color={selectedCharge === 'ExploitationAutre' ? '#1FC607' : '#245776'} size="120px" />
              <p className={selectedCharge === 'ExploitationAutre' ? 'selected' : ''}>Expoitation et autres éléments de rémunération</p>
            </div> */}

            <hr />

            {/* Assurances */}
            {/* { API_URL + '/' + selectedFilePath } */}
            {selectedCharge === "Assurances" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Insérer le document</p>
                    </div>
                    <div className="leftSideP">
                      <p>Insérer la date</p>
                    </div>
                  </div>
                  <div className="inputs">
                    {/* {JSON.stringify(selectedFilePath)} */}
                    <div className="input">
                      {selectedFile ? (
                        <Fragment>
                          <label
                            htmlFor="assuranceFile"
                            className="selectedFileStyle"
                          >
                            {selectedFile.name}
                          </label>
                          { selectedFilePath && (<BsFileEarmarkArrowDown className="mx-3" size={30} color="#3A7E8B" style={{cursor: 'pointer'}} onClick={handleUploadedFileDownload} />) }
                        </Fragment>
                      ) : (
                        <Fragment >
                          <label
                            htmlFor="assuranceFile"
                            className="notSelectedFileStyle"
                          >
                            Choisir un document
                          </label>
                          
                        </Fragment>
                      )}
                      <input
                        type="file"
                        id="assuranceFile"
                        className="d-none"
                        onChange={handleAssuranceFileChange}
                      />
                    </div>
                    <div className="input">
                      {/* {dayjs(selectedChargeDate).format(dateFormat)} */}
                      {
                        selectedChargeDate ?
                          <DatePicker
                            format={dateFormat}
                            value={dayjs(selectedChargeDate, dateFormat)}
                            onChange={handleDateChange1}
                          /> :
                          <DatePicker
                            format={dateFormat}
                            onChange={handleDateChange1}
                          />
                      }
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Assurances")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Assurances")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Taxes par véhicule */}
            {selectedCharge === "Taxes" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Insérer le document</p>
                    </div>
                    <div className="leftSideP">
                      <p>Insérer la date</p>
                    </div>
                  </div>
                  <div className="inputs">
                    <div className="input">
                      {selectedFile ? (
                        <Fragment>
                          <label
                            htmlFor="assuranceFile"
                            className="selectedFileStyle"
                          >
                            {selectedFile.name}
                          </label>

                          { selectedFilePath && (<BsFileEarmarkArrowDown className="mx-3" size={30} color="#3A7E8B" style={{cursor: 'pointer'}} onClick={handleUploadedFileDownload} />) }
                        </Fragment>
                      ) : (
                        <Fragment>
                          <label
                            htmlFor="assuranceFile"
                            className="notSelectedFileStyle"
                          >
                            Choisir un document
                          </label>
                        </Fragment>
                      )}
                      <input
                        type="file"
                        id="assuranceFile"
                        className="d-none"
                        onChange={handleAssuranceFileChange}
                      />
                    </div>
                    <div className="input">
                      {/* {dayjs(selectedChargeDate).format(dateFormat)} */}
                      {selectedChargeDate2 ? 
                        <DatePicker
                          format={dateFormat}
                          value={dayjs(selectedChargeDate2, dateFormat)}
                          onChange={handleDateChange2}
                        /> :
                        <DatePicker
                          format={dateFormat}
                          onChange={handleDateChange2}
                        />
                      }

                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Taxes")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Taxes")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Carburant */}
            {selectedCharge === "Carburant" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget Annuel</p>
                    </div>
                    {/* <div className="leftSideP">
                      <p>Insérer la date</p>
                    </div> */}
                  </div>
                  <div className="inputs">
                    <div className="input">
                      <div>
                        <Input
                          placeholder=""
                          type="number"
                          name="annualBudget"
                          onChange={handleInputChange}
                          value={formValues.annualBudget}
                        />
                      </div>
                      <span>MAD</span>
                    </div>
                    {/* <div className="input">
                      <DatePicker onChange={handleDateChange} />
                    </div> */}
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Carburant")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Carburant")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Pneumatique */}
            {selectedCharge === "Pneumatiques" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget pneu/voiture</p>
                    </div>
                    <div className="leftSideP">
                      <p>Budget Annuel</p>
                    </div>
                  </div>
                  <div className="inputs_2">
                    <div className="input">
                      <div>
                        <label htmlFor="Price_id">Prix</label>
                        <Input
                          placeholder=""
                          type="number"
                          id="Price_id"
                          name="carTiresPrice"
                          onChange={handleInputChange}
                          value={formValues.carTiresPrice}
                        />
                      </div>
                    </div>
                    <div className="input">
                      <div>
                        <label htmlFor="Quantity_id">Quantité</label>
                        <Input
                          placeholder=""
                          type="number"
                          id="Quantity_id"
                          name="carTiresQuantity"
                          onChange={handleInputChange}
                          value={formValues.carTiresQuantity}
                        />
                      </div>
                    </div>
                    <div className="input">
                      <div>
                        <label htmlFor="Duration_id">Durée</label>
                        <Input
                          placeholder=""
                          id="Duration_id"
                          name="carTiresDuration"
                          onChange={handleInputChange}
                          value={formValues.carTiresDuration}
                        />
                      </div>
                    </div>
                    <div className="input lastInput">
                      <div>
                        <Input
                          placeholder=""
                          type="number"
                          name="annualBudget"
                          onChange={handleInputChange}
                          value={formValues.annualBudget}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Pneumatiques")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Pneumatiques")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Péage */}
            {selectedCharge === "Peages" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget Annuel</p>
                    </div>
                  </div>
                  <div className="inputs">
                    <div className="input">
                      <div>
                        <Input
                          placeholder=""
                          type="number"
                          name="annualBudget"
                          onChange={handleInputChange}
                          value={formValues.annualBudget}
                        />
                      </div>
                      <span>MAD</span>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Peages")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Peages")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* L'entretien-réparation */}
            {selectedCharge === "Entretien" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget Annuel</p>
                    </div>
                  </div>
                  <div className="inputs">
                    <div className="input">
                      <div>
                        <Input
                          placeholder=""
                          type="number"
                          name="annualBudget"
                          onChange={handleInputChange}
                          value={formValues.annualBudget}
                        />
                      </div>
                      <span>MAD</span>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Entretien")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Entretien")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Indemnités de déplacement */}
            {selectedCharge === "Deplacement" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget Annuel</p>
                    </div>
                  </div>
                  <div className="inputs">
                    <div className="input">
                      <div>
                        <Input
                          placeholder=""
                          type="number"
                          name="annualBudget"
                          onChange={handleInputChange}
                          value={formValues.annualBudget}
                        />
                      </div>
                      <span>MAD</span>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("Deplacement")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("Deplacement")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Autre cout à inclure dans la recomposition annuelle */}
            {selectedCharge === "AutreCout" && (
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget Annuel</p>
                    </div>
                  </div>
                  <div className="inputs">
                    <div className="input">
                      <div>
                        <Input
                          placeholder=""
                          type="number"
                          name="annualBudget"
                          onChange={handleInputChange}
                          value={formValues.annualBudget}
                        />
                      </div>
                      <span>MAD</span>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button
                        color="success"
                        outline
                        disabled={numberOfVariableLoad === 1}
                        onClick={() => handleAddVariableLoad("AutreCout")}
                      >
                        Valider
                      </Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button
                      color="warning"
                      outline
                      disabled={numberOfVariableLoad === 0}
                      onClick={() => handleAddVariableLoad("AutreCout")}
                      >
                        Modifier
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Cout de structure et autres charges */}
            {/* {selectedCharge === "CoutDeStructure" && 
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Budget pneu/voiture</p>
                    </div>
                  </div>
                  <div className="inputs_2 inputs_2_grid_2">
                    <div className="input">
                      <div>
                        <label htmlFor="Price_id">Price</label>
                        <Input placeholder="" id="Price_id" />
                      </div>
                    </div>
                    <div className="input">
                      <div>
                        <label htmlFor="Quantity_id">Quantity</label>
                        <Input placeholder="" id="Quantity_id" />
                      </div>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button color="success" outline>Valider</Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button color="danger" outline>Annuler</Button>
                    </div>
                  </div>
                </div>
              </div>
            } */}

            {/* Expoitation et autres éléments de rémunération */}
            {/* {selectedCharge === "ExploitationAutre" && 
              <div className="bottomContents">
                <div className="contents">
                  <div className="leftSide">
                    <div className="leftSideP">
                      <p>Exploitation et autres éléments de rémunération</p>
                    </div>
                  </div>
                  <div className="inputs">
                    <div className="input">
                      <div>
                        <Input placeholder="" />
                      </div>
                      <span>MAD</span>
                    </div>
                  </div>
                  <div className="rightSide">
                    <div className="rightSideBtn">
                      <Button color="success" outline>Valider</Button>
                    </div>
                    <div className="rightSideBtn">
                      <Button color="danger" outline>Annuler</Button>
                    </div>
                  </div>
                </div>
              </div>
            } */}
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default ChargeVariable;
