import React, { useContext, useState } from 'react'
import { Fragment } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'

import { useNavigate } from 'react-router-dom';
import { AuthContext } from './../../context/authContext';
import { signIn } from './../../api/superAdmin';

import Swal from 'sweetalert2';

function Login() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userForm, setUserForm] = useState({
    "email": "",
    "password": ""
  })

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await signIn(userForm.email, userForm.password)
      await login()

      sessionStorage.setItem('token', response.token)
      sessionStorage.setItem('id', response.userInfo._id)

      navigate('/admin')
    } catch (err) {
      Swal.fire({
        title: 'Erreur!',
        text: err.response.data.error,
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUserForm({ ...userForm, [name]: value });
  }

  return (
    <Fragment>
        <section className='login_section'>
          <div className='container h-100'>
            <div className="contents">
              <h1>
                Royaume du Maroc <br />
                Ministère de l'intérieur <br />
              </h1>
              <img src={require('./../../assets/img/logo/logo.png')} alt="" />
              <h1>
                Wilaya de la Region <br />
                Sous Massa Préfecture <br />
                d'Agadir Idda Outanane <br />
              </h1>

              <h2>Accéder à votre espace e-wilaya</h2>

              <form>
                <FormGroup>
                  <Label for="emailID">
                    Email
                  </Label>
                  <Input
                    id="emailID"
                    className='shadow-none mb-4'
                    placeholder="Entrez votre adress email"
                    type="email"
                    onChange={handleInputChange}
                    value={userForm.email}
                    name="email"
                  />

                  <Label for="passwordID">
                    Mot de passe
                  </Label>
                  <Input
                    id="passwordID"
                    className='shadow-none mb-4'
                    placeholder="Entrez votre mot de passe"
                    type="password"
                    onChange={handleInputChange}
                    value={userForm.password}
                    name="password"
                  />
                </FormGroup>

                <Button className='submitButton' onClick={handleSubmit}> S'identifier </Button>
              </form>
            </div>
          </div>
        </section>
    </Fragment>
  )
}

export default Login