import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button } from "reactstrap";
import { AiFillDelete } from "react-icons/ai";

import SideBar from "../../components/SideBar";
import Header from "../../components/DataManagement/Responsable/Header";

import { getAllRH, updateRHStatus, deleteOneRH } from "./../../api/data/RH";

import Swal from "sweetalert2";

function ResponsableTable() {
  const [RHData, setRHData] = useState([]);

  const getRHData = async () => {
    try {
      const response = await getAllRH();
      setRHData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = async (id, status) => {
    try {
      Swal.fire({
        title: status === 'V' ? 'Voulez vous valider cette opération' : 'Voulez vous annuler cette opération',
        icon: "warning",
        confirmButtonColor: status === 'V' ? "#198754" : "#dc3545",
        confirmButtonText: status === 'V' ? "Valider" : "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await updateRHStatus(id, status)
                if(response) {
                  getRHData()
                  Swal.fire(status === 'V' ? "Validée" : "Annulée", "", "success");
                }
            }
            catch(err) {
                Swal.fire("Erreur", "", "error");
            }

        //   Swal.fire(status === 'V' ? "Validée" : "Annulée", "", "success");
        }
      });
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    try {
        Swal.fire({
          title: 'Voulez vous supprimer cette opération',
          icon: "warning",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Supprimer",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
                const response = await deleteOneRH(id)
                if(response) {
                  getRHData()
                  Swal.fire("Suprimée", "", "success");
                }
            }
            catch(err) {
                Swal.fire("Erreur", "", "error");
            }

            // Swal.fire("Suprimée", "", "success");
          }
        });
      } catch (err) {}
  }

  useEffect(() => {
    getRHData();
  }, []);

  return (
    <section className="responsableTableSection">
      <div className="contents">
        <Row>
          <Col md="2" className="p-0">
            <SideBar />
          </Col>
          <Col md="10" className="p-0">
            <Header />

            <div className="table">
              <Table striped>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Grade</th>
                    <th>Fonction</th>
                    <th>Carte nationale</th>
                    <th>Email</th>
                    <th>Etat d'opération</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {RHData.map((el) => (
                    <tr>
                      <td>
                        {el.firstName} - {el.lastName}
                      </td>
                      <td>{el.grade}</td>
                      <td>{el.fonction}</td>
                      <td>{el.cin}</td>
                      <td>{el.email}</td>
                      <td>
                        {el.status === "N" && (
                          <div className="btnStatus">
                            <Button
                              size="sm"
                              color="success"
                              onClick={() => handleStateChange(el._id, 'V')}
                            >
                              Valider
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => handleStateChange(el._id, 'A')}
                            >
                              Annuler
                            </Button>
                          </div>
                        )}

                        {el.status === "V" && (
                          <div className="btnStatus">
                            <Button size="sm" color="success" disabled>
                              Validée
                            </Button>
                          </div>
                        )}

                        {el.status === "A" && (
                          <div className="btnStatus">
                            <Button size="sm" color="danger" disabled>
                              Annulée
                            </Button>
                          </div>
                        )}
                      </td>
                      <td>
                        <AiFillDelete size="1.5rem" color="red" onClick={() => handleDelete(el._id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default ResponsableTable;
