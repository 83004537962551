import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Input, Card } from "antd";

import SideBar from "../../components/SideBar";
import { addDocument } from "./../../api/data/Documents"

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function NewDocument() {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        immatriculation: "",
        cartGris: "",
        vignette: "",
        assurance: "",
        visiteTechnique: ""
    })

    const handleInputChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleFormSubmit = async e => {
        e.preventDefault()
        try {
            const response = await addDocument(formData.immatriculation, formData.cartGris, formData.vignette, formData.assurance, formData.visiteTechnique)

            if(!response) {
                return Swal.fire({
                    title: 'Erreur!',
                    text: 'Server error',
                    icon: 'error'
                });
            }

            Swal.fire({
                title: 'Ajoutée',
                text: '',
                icon: 'success'
            });

            navigate('/admin/documentTable')
        }
        catch(err) {
            Swal.fire({
                title: 'Erreur!',
                text: err.response.data.error,
                icon: 'error'
            });
        }
    }
  return (
    <section className='NewDocSection'>
        <Row>
            <Col md="2" className='p-0'>
                <SideBar />
            </Col>
            <Col md="10" className='p-0'>
                <div className="contents">
                    <Card style={{ width: "60%" }}>
                        <div className="inputContents">
                            <Input placeholder="Immactriculation" onChange={handleInputChange} name='immatriculation' value={formData.immatriculation} />
                            <Input placeholder="Carte grise" onChange={handleInputChange} name='cartGris' value={formData.cartGris} />
                            <Input placeholder="Vignette" onChange={handleInputChange} name='vignette' value={formData.vignette} />
                            <Input placeholder="Assurance" onChange={handleInputChange} name='assurance' value={formData.assurance} />
                            <Input placeholder="Visite technique" onChange={handleInputChange} name='visiteTechnique' value={formData.visiteTechnique} />
                        </div>
                        <div className="submitBtn">
                            <Button onClick={handleFormSubmit}>Valider</Button>
                        </div>
                    </Card>
                </div>
            </Col>
        </Row>
    </section>
  )
}

export default NewDocument
