import API_URL from "../config";
import axios from "axios";

export const addNewOperationApi = async (startCityName, startCityLocation, arrivedCityName, arrivedCityLocation, selectedModel, selectedConducteur, selectedMatricule, SelectedFromDate, SelectedToDate) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const formObject = {
            startCityName: startCityName,
            startCityLocation: startCityLocation,
            arrivedCityName: arrivedCityName,
            arrivedCityLocation: arrivedCityLocation,
        
            selectedModel: selectedModel,
            selectedConducteur: selectedConducteur,
            selectedMatricule: selectedMatricule,
        
            SelectedFromDate: SelectedFromDate,
            SelectedToDate: SelectedToDate,
        }

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/operation/add/${id}`, formObject, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const editeOperationApi = async (startCityName, startCityLocation, arrivedCityName, arrivedCityLocation, selectedModel, selectedConducteur, selectedMatricule, SelectedFromDate, SelectedToDate, operationID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const formObject = {
            startCityName: startCityName,
            startCityLocation: startCityLocation,
            arrivedCityName: arrivedCityName,
            arrivedCityLocation: arrivedCityLocation,
        
            selectedModel: selectedModel,
            selectedConducteur: selectedConducteur,
            selectedMatricule: selectedMatricule,
        
            SelectedFromDate: SelectedFromDate,
            SelectedToDate: SelectedToDate,
        }

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/operation/update/${id}/${operationID}`, formObject, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getOneOperationApi = async (operationID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/getOne/${id}/${operationID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllOperationApi = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/getAll/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllPlanifiedOperationApi = async (startCityName, arrivedCityName, FiltredFromDate, FiltredToDate, selectedModel, selectedConducteur) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/getPlanifiedDirection/${id}?startCityName=${startCityName}&arrivedCityName=${arrivedCityName}&FiltredFromDate=${FiltredFromDate}&FiltredToDate=${FiltredToDate}&selectedModel=${selectedModel}&selectedConducteur=${selectedConducteur}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllCurrentOperationApi = async (startCityName, arrivedCityName, FiltredFromDate, FiltredToDate, selectedModel, selectedConducteur) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/getCurrentDirection/${id}?startCityName=${startCityName}&arrivedCityName=${arrivedCityName}&FiltredFromDate=${FiltredFromDate}&FiltredToDate=${FiltredToDate}&selectedModel=${selectedModel}&selectedConducteur=${selectedConducteur}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllFinishedOperationApi = async (startCityName, arrivedCityName, FiltredFromDate, FiltredToDate, selectedModel, selectedConducteur) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/getFinishedDirection/${id}?startCityName=${startCityName}&arrivedCityName=${arrivedCityName}&FiltredFromDate=${FiltredFromDate}&FiltredToDate=${FiltredToDate}&selectedModel=${selectedModel}&selectedConducteur=${selectedConducteur}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const countAllPlanifiedOperationApi = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/countPlanifiedDirection/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const countAllCurrentOperationApi = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/countCurrentDirection/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const countAllFinishedOperationApi = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/operation/countFinishedDirection/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}
