import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.28 89.28" width={size} height={size}>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <path class="cls-3" fill={fill} d="M33.15,79.07h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-3" fill={fill} d="M106.74,79.07h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-3" fill={fill} d="M33.15,79.07c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-3" fill={fill} d="M33.15,79.07c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-3" fill={fill} d="M106.74,79.07c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <path class="cls-3" fill={fill} d="M106.74,79.07c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <rect class="cls-3" fill={fill} x="34.5" y="80.12" width="52.34" height="2.55"/>
          </g>
          <path class="cls-3" fill={fill} d="M113.27,62.65c-1.27-1.27-2.88-2.01-4.67-2.47h.02V4.68c.65,0,1.23-.26,1.65-.69,.43-.42,.69-1,.69-1.65s-.26-1.23-.69-1.65c-.42-.43-1-.69-1.65-.69h-40.59c-1.3,0-2.34,1.05-2.34,2.34,0,.65,.26,1.23,.68,1.65,.42,.43,1.01,.69,1.66,.69v31.02l5.95,.4c1.97,.14,3.91,.61,5.71,1.4,2.19-1.87,5.03-2.99,8.12-2.99,6.94,0,12.56,5.62,12.56,12.55,0,3.61-1.52,6.87-3.97,9.15l-1.94-2.5c-.48-.74-.98-1.47-1.5-2.18-2.68-3.64-5.85-6.88-9.42-9.63-.92-.71-1.86-1.39-2.83-2.03l-.35-.23c-1.9-1.26-4.14-1.93-6.42-1.93H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM4.21,72.11c0,.46-.37,.83-.83,.83s-.83-.37-.83-.83v-16.72c0-.46,.37-.83,.83-.83s.83,.37,.83,.83v16.72Zm84.89-17.55h-18.89v-12.39h8.78c1.26,0,2.47,.45,3.41,1.25,.28,.23,.53,.49,.76,.78l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.6,4.2c.2,.33-.04,.77-.43,.77Zm-13.69-30.22c-1.21,0-2.19-.98-2.19-2.19V9.13c0-1.21,.98-2.19,2.19-2.19h26.21c1.21,0,2.19,.98,2.19,2.19v13.02c0,1.21-.98,2.19-2.19,2.19h-26.21Z"/>
          <path class="cls-3" fill={fill} d="M92.96,51.53c-2.68-3.64-5.85-6.88-9.42-9.63,.11-.12,.22-.24,.34-.36l3.26-3.25,3.3,3.3c2.69,2.69,3.53,6.53,2.52,9.94Z"/>
          <path class="cls-3" fill={fill} d="M92.9,43.28l-1.94-2.94c-.47-.88-.38-1.96,.21-2.76l.61-.82,2.37,3.48c.84,1.23,1.16,2.75,.89,4.22l-.17,.9h0c-.96-.29-1.51-1.2-1.98-2.09Z"/>
          <path class="cls-1" strokeWidth="2px" fill="none" stroke={fill} strokeLinecap="round" strokeMiterlimit="10" d="M107.86,32.51s3.53,4.94,3.7,8.55c.07,1.48,.08,2.96,.07,4.21-.02,2.07,.64,4.08,1.84,5.76h0c1.46,2.03,4.38,2.26,6.14,.5h0c1.89-1.89,2.96-4.46,2.96-7.14V13.27c0-1.06,.51-2.06,1.37-2.67l6.34-4.55"/>
          <path class="cls-2" strokeWidth="1.55px" fill="none" stroke={fill} strokeLinecap="round" strokeMiterlimit="10" d="M122.57,19.11l.48-.34c1.21-.85,2.04-2.39,2.24-4.15h0c.05-.45,.06-.91,.02-1.37l-.24-3.1"/>
        </g>
      </g>
    </svg>
  );
}

export default Assurances;
