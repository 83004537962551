import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button } from "reactstrap";
import { AiFillDelete } from "react-icons/ai";

import SideBar from "../../components/SideBar";
import Header from "../../components/DataManagement/Document/Header";

import {
  getAllDocument,
  updateDocumentStatus,
  deleteOneDocument,
} from "./../../api/data/Documents";
import Swal from "sweetalert2";

function DocumentTable() {
  const [DocumentData, setDocumentData] = useState([]);

  const getDocumentData = async () => {
    try {
      const response = await getAllDocument();
      setDocumentData(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = async (id, status) => {
    try {
      Swal.fire({
        title:
          status === "V"
            ? "Voulez vous valider cette opération"
            : "Voulez vous annuler cette opération",
        icon: "warning",
        confirmButtonColor: status === "V" ? "#198754" : "#dc3545",
        confirmButtonText: status === "V" ? "Valider" : "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await updateDocumentStatus(id, status);
            if (response) {
                getDocumentData()
                Swal.fire(status === "V" ? "Validée" : "Annulée", "", "success");
            }
          } catch (err) {
            Swal.fire("Erreur", "", "error");
          }

          //   Swal.fire(status === 'V' ? "Validée" : "Annulée", "", "success");
        }
      });
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "Voulez vous supprimer cette opération",
        icon: "warning",
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Supprimer",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await deleteOneDocument(id);
            if (response) {
                getDocumentData()
                Swal.fire("Supprimée", "", "success");
            } 
          } catch (err) {
            Swal.fire("Erreur", "", "error");
          }
        }
      });
    } catch (err) {}
  };

  useEffect(() => {
    getDocumentData();
  }, []);
  return (
    <section className="documentTableSection">
      <div className="contents">
        <Row>
          <Col md="2" className="p-0">
            <SideBar />
          </Col>
          <Col md="10" className="p-0">
            <Header />

            <div className="table">
              <Table striped>
                <thead>
                  <tr>
                    <th>Immatriculation</th>
                    <th>Carte grise</th>
                    <th>Vignette</th>
                    <th>Assurance</th>
                    <th>Visite technique</th>
                    <th>Etat d'opération</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {DocumentData.map((el) => (
                    <tr>
                      <td>{el.immatriculation}</td>
                      <td>{el.cartGris}</td>
                      <td>{el.vignette}</td>
                      <td>{el.assurance}</td>
                      <td>{el.visiteTechnique}</td>
                      <td>
                        {el.status === "N" && (
                          <div className="btnStatus">
                            <Button
                              size="sm"
                              color="success"
                              onClick={() => handleStateChange(el._id, "V")}
                            >
                              Valider
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => handleStateChange(el._id, "A")}
                            >
                              Annuler
                            </Button>
                          </div>
                        )}

                        {el.status === "V" && (
                          <div className="btnStatus">
                            <Button size="sm" color="success" disabled>
                              Validée
                            </Button>
                          </div>
                        )}

                        {el.status === "A" && (
                          <div className="btnStatus">
                            <Button size="sm" color="danger" disabled>
                              Annulée
                            </Button>
                          </div>
                        )}
                      </td>
                      <td>
                        <AiFillDelete
                          size="1.5rem"
                          color="red"
                          onClick={() => handleDelete(el._id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default DocumentTable;
