import API_URL from "../config";
import axios from "axios";

export const getEvaluationData = async (typeOf, sectedYear) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/evaluation/${typeOf}/${sectedYear}/${id}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}
