import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

function Header() {
  return (
    <div className='headerComponent'>
        <div className="contents">
            <div className="rowContents">
                <p></p>
                <Link to="/admin/chargeVariable">
                    <Button className='submitButton'> Charge variable </Button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Header
