import React, { Fragment, useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

export const options = {
  title: "",
  curveType: "function",
  legend: { position: "bottom" },
};

function LineChart({dataByMonth}) {
  const [initialData, setInitialData] = useState([
    // ["Year", "Budget aloué", "Budget consommé"],
    // ["Jan", 0, 0],
    // ["Fev", 0, 0],
    // ["Mar", 0, 0],
    // ["Avr", 1030, 540],
    // ["Mai", 1030, 540],
    // ["Jui", 1030, 540],
    // ["Jui", 1030, 540],
    // ["Auo", 1030, 540],
    // ["Sep", 1030, 540],
    // ["Oct", 1030, 540],
    // ["Nov", 1030, 540],
    // ["Dec", 1030, 540],
  ])

  useEffect(() => {
    setInitialData(dataByMonth)
    console.log('dataByMonth', dataByMonth)
  }, [dataByMonth])

  return (
    <Fragment>
        <section>
            <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={initialData}
                options={options}
            />
        </section>
    </Fragment>
  )
}

export default LineChart
