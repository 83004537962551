import API_URL from '../config';
import axios from 'axios';

export const signIn = async (email, password) => {
    try {
        let adminObject = {
          email: email,
          password: password
        };
    
        const res = await axios.post(`${API_URL}/api/superAdmin/signIn`, adminObject);
        return res.data;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
}

export const isAuthenticatedApi = async() => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/superAdmin/${id}`, option);

        if(res.data)
            return true
    }
    catch (err) {
        return false
    }
}
