import API_URL from "../config";
import axios from "axios";

export const getAllCityApi = async () => {
    try {
        const res = await axios.get(`${API_URL}/api/cities`)
        return res.data
    }
    catch(err) {
        console.log(err)
        throw err
    }
}
