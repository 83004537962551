import { useEffect, useState } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"
});

function MapComponent({ startPoint, endPoint }) {
  const map = useMap();
  const [routingControl, setRoutingControl] = useState(null);

  useEffect(() => {
    if (!map) return;

    // Create the routing control initially with waypoints
    const initialRoutingControl = L.Routing.control({
      waypoints: [
        L.latLng(startPoint.lat, startPoint.lng),
        L.latLng(endPoint.lat, endPoint.lng)
      ],
      lineOptions: {
        styles: [{ color: "#000000", weight: 1 }]
      },
      show: false,
      addWaypoints: false,
      routeWhileDragging: false,
      draggableWaypoints: false,
      showAlternatives: false,
      fitSelectedRoutes: true
    });

    // Add the initial routing control to the map
    initialRoutingControl.addTo(map);

    // Store the routing control in state
    setRoutingControl(initialRoutingControl);

    // eslint-disable-next-line
  }, [map]);

  // Update routing control with new waypoints when props change
  useEffect(() => {
    if (!routingControl) return;

    // Update waypoints
    const newWaypoints = [
      L.latLng(startPoint.lat, startPoint.lng),
      L.latLng(endPoint.lat, endPoint.lng)
    ];

    // Update routing control's waypoints and redraw the route
    routingControl.setWaypoints(newWaypoints);
  }, [startPoint, endPoint, routingControl]);

  return null;
}

export default MapComponent;
