import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import SideBar from "../../components/SideBar";
import { Form, Select, DatePicker, Input, Card } from "antd";
import { getAllCityApi } from "../../api/city";

const { Option } = Select;

function NewItemsManagement() {
  const [cityData, setCityData] = useState([]);

  useEffect(() => {
    getAllCities();
  }, []);

  const getAllCities = async () => {
    const cityRes = await getAllCityApi();
    setCityData(cityRes);
  };

  const onChange = () => {};
  return (
    <section className="NewItemsManagementSection">
      <Row>
        <Col md="2" className="p-0">
          <SideBar />
        </Col>
        <Col md="10" className="p-0">
          <div className="contents">
            <div className="topInput">
                <div className="input">
                    <DatePicker onChange={onChange} placeholder="Selectionner la date" />
                </div>
                <div className="input">
                    <Form.Item name="select-multiple">
                        <Select placeholder="Ville">
                        {cityData.map((el) => (
                            <Option value={el.ville}>{el.ville}</Option>
                        ))}
                        </Select>
                    </Form.Item>
                </div>
                <div className="input">
                    <Input placeholder="Choisir N° modèle" />
                </div>
                <div className="input">
                    <Input placeholder="Choisir N° matricule" />
                </div>
            </div>

            <hr />

            <div className="bodyInput">
              <div className="left">
                <h3>Information sur l'article commandé</h3>
                <div className="left_side_input">
                    <Input placeholder="N° Article" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="Désignation" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="Responsable de véhicule" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="Quantité" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="Prix unitaire" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="Prix total de la commande" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="Prestataire" />
                </div>
                <div className="left_side_input">
                    <Input placeholder="ICE" />
                </div>
              </div>
              <div className="right">
                <Card style={{ width: "90%" }}>
                    <h3>326-9005 CAMSHAFT GP</h3>
                    <p>S/N: C4Z1 - UP</p>
                    <p>PART OF 354-7363, 364-5743 ENGINE AR-PRIMARY AN ATTACHMENT</p>
                </Card>
              </div>
            </div>

            <div className="submitBtn">
                <Button>Valider</Button>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default NewItemsManagement;
