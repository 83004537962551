import React from 'react'

function Header() {
  return (
    <div className='headerComponent'>
        <div className="contents">
            <div className="rowContents">
                <p>Évaluation</p>
                {/* <Link to="/admin/chargeVariable">
                    <Button className='submitButton'> Charge variable </Button>
                </Link> */}
            </div>
        </div>
    </div>
  )
}

export default Header
