import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from "react-router-dom";

import routes from "./../routes";

import { AuthContext } from './../context/authContext';

function Admin() {
    const { isAuthenticated } = useContext(AuthContext);
    const [isAuthenticatedAdmin, setIsAuthenticatedAdmin] = useState(isAuthenticated);

    useEffect(() => {
        setIsAuthenticatedAdmin(isAuthenticated);

        console.log("isAuthenticated", isAuthenticated)
    }, [isAuthenticated]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
            return (
                isAuthenticatedAdmin ?
                    <Route
                        path={prop.path}
                        element={prop.component}
                        key={key}
                        exact
                    />
                        :
                    <Route
                        path={prop.path}
                        element={<Navigate to="/auth/login" replace />}
                        key={key}
                        exact
                    />
            );
            } else {
            return null;
            }
        });
    };

  return (
    <Fragment>
        <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>
    </Fragment>
  )
}

export default Admin
