import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133.99 83.1" width={size} height={size}>
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <g>
        <path class="cls-1" fill={fill} d="M33.15,72.89h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
        <path class="cls-1" fill={fill} d="M106.74,72.89h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
        <path class="cls-1" fill={fill} d="M33.15,72.89c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
        <path class="cls-1" fill={fill} d="M33.15,72.89c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
        <path class="cls-1" fill={fill} d="M106.74,72.89c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
        <path class="cls-1" fill={fill} d="M106.74,72.89c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
        <rect class="cls-1" fill={fill} x="34.5" y="73.94" width="52.34" height="2.55"/>
        <path class="cls-1" fill={fill} d="M73.94,31.53h-1.12v1.01h3.02l.18-.82c-.68-.13-1.38-.19-2.08-.19Z"/>
      </g>
      <path class="cls-1" fill={fill} d="M113.27,56.47c-1.85-1.85-4.42-2.57-7.21-2.99-2.23-.34-7.04-1.34-8.41-2-.08-.04-.14-.07-.19-.1l-3-3.85c-3.59-5.51-8.27-10.21-13.75-13.84l-.35-.23c-1.32-.87-2.8-1.46-4.34-1.74l-.18,.82h-3.02v-1.01H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM4.21,65.93c0,.45-.37,.83-.83,.83s-.83-.38-.83-.83v-16.72c0-.46,.37-.83,.83-.83s.83,.37,.83,.83v16.72Zm84.89-17.55h-18.89v-12.39h8.78c1.63,0,3.17,.75,4.17,2.03l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.6,4.2c.2,.33-.04,.77-.43,.77Z"/>
      <path class="cls-2" fill={fill} stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth="2px" d="M76.02,31.72l.21-.92,3.5-16.07c.14-.66,.73-1.14,1.41-1.14h50.41c.89,0,1.57,.81,1.42,1.69l-5.82,34.99c-.11,.7-.72,1.21-1.42,1.21h-28.08c-.08-.04-2.95,0-2.66-.1"/>
      <path class="cls-2" fill={fill} stroke="#FFFFFF" strokeMiterlimit="10" strokeWidth="2px" d="M128.23,7.63v5.96h-47.09c-.68,0-1.27,.48-1.41,1.14l-3.5,16.07-.21,.92-.18,.82h-3.02V1.83c0-.46,.38-.83,.84-.83h14.92c.22,0,.43,.09,.58,.24l4.09,4.09c.58,.58,1.35,.9,2.16,.9h31.41c.78,0,1.41,.63,1.41,1.4Z"/>
    </g>
  </g>
</svg>
  );
}

export default Assurances;
