import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

function Header() {
  return (
    <div className='headerComponent'>
        <div className="contents">
            <div className="rowContents">
                <p>Flotte</p>
                <Link to="/admin/newFloat">
                    <Button className='submitButton'> Ajouter une véhicule </Button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default Header
