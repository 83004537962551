import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Input, Card, DatePicker, Select } from "antd";

import SideBar from "../../components/SideBar";
import { listOfMatricul, listOfResponsable } from "../../api/data/Vehiculs";

import { addVehicul } from '../../api/data/Vehiculs'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

function NewFloat() {
    const navigate = useNavigate()

    const [FormData, setFormData] = useState({
        marque: "",
        modele: "",
        miseEnCirculation: "",
        immatriculation: "",
        responsable: "",
        cartGris: "",
        prefectorale: ""  
    })

  const [RHNames, setRHNames] = useState([]);
  const [MatriculNames, setMatriculNames] = useState([]);

  const getAllMatriculAndRH = async () => {
    try {
      const Matricul = await listOfMatricul();
      setMatriculNames(Matricul);

      const RH = await listOfResponsable();
      setRHNames(RH);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllMatriculAndRH();
  }, []);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({ ...FormData, [name]: value })
  };

  const handleDateChange = (date, dateString) => {
    setFormData({ ...FormData, miseEnCirculation: dateString })
  }

  const handleSelectChange = (e, name) => {
    setFormData({ ...FormData, [name.key]: e })
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    try {
        const response = await addVehicul(FormData.marque, FormData.modele, FormData.miseEnCirculation, FormData.immatriculation, FormData.responsable, FormData.cartGris, FormData.prefectorale)

        if(!response) {
            return Swal.fire({
                title: 'Erreur!',
                text: 'Server error',
                icon: 'error'
            });
        }

        Swal.fire({
            title: 'Ajoutée',
            text: '',
            icon: 'success'
        });

        navigate('/admin/floatTable')
    }
    catch(err) {
        Swal.fire({
            title: 'Erreur!',
            text: err.response.data.error,
            icon: 'error'
        });
    }
  }

  return (
    <section className="NewFloatSection">
      <Row>
        <Col md="2" className="p-0">
          <SideBar />
        </Col>
        <Col md="10" className="p-0">
          <div className="contents">
            <Card style={{ width: "60%" }}>
              <div className="inputContents">
                <Input placeholder="Marque de véhicule" name="marque" onChange={handleInputChange} />
                <Input placeholder="Modèle" name="modele" onChange={handleInputChange} />
                <DatePicker
                  placeholder="Date mise en circulation"
                  onChange={handleDateChange}
                />

                <Select placeholder="Responsable" onChange={handleSelectChange}>
                  {RHNames.map((el) => (
                    <Option key="responsable" value={el._id}>
                      {el.firstName} {el.lastName}
                    </Option>
                  ))}
                </Select>
                <Select placeholder="Immactriculation" onChange={handleSelectChange}>
                  {MatriculNames.map((el) => (
                    <Option key="immatriculation" value={el._id}>{el.immatriculation}</Option>
                  ))}
                </Select>

                <Input placeholder="Affectation ou carte grise" name="cartGris" onChange={handleInputChange} />
                <Input placeholder="Affectation préfectorale" name="prefectorale" onChange={handleInputChange} />
              </div>
              <div className="submitBtn">
                <Button onClick={handleFormSubmit}>Valider</Button>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default NewFloat;
