import Login from "./views/pages/Login";
import Home from "./views/pages/Home"
import OperationManagement from "./views/pages/OperationManagement"
import NewOperationManagement from "./views/pages/NewOperationManagement"
import EditeOperationManagement from "./views/pages/EditeOperationManagement"
import MissionOrder from "./views/pages/MissionOrder"
import ItemsManagement from "./views/pages/ItemsManagement"
import NewItemsManagement from "./views/pages/NewItemsManagement"
import DataManagement from "./views/pages/DataManagement"

import ResponsableTable from "./views/pages/ResponsableTable"
import FloatTable from "./views/pages/FloatTable"
import DocumentTable from "./views/pages/DocumentTable"

import NewResponsable from "./views/pages/NewResponsable"
import NewFloat from "./views/pages/NewFloat"
import NewDocument from "./views/pages/NewDocument"

import ChargeVariable from "./views/pages/ChargeVariable"

import Evaluation from "./views/pages/Evaluation"

import { FaUserAlt } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: <FaUserAlt />,
    component: <Login />,
    layout: "/auth",
    show: false
  },
  {
    path: "/index",
    name: "Tableau de bord",
    icon: <IoSettings />,
    component: <Home />,
    layout: "/admin",
    show: true
  },
  {
    path: "/missionOrder/:missionOrderID",
    name: "Ordre de mission",
    icon: <IoSettings />,
    component: <MissionOrder />,
    layout: "/admin",
    show: false,
    params: true
  },
  {
    path: "/NewGestionOP",
    name: "Nouvelle opération",
    icon: <IoSettings />,
    component: <NewOperationManagement />,
    layout: "/admin",
    show: false
  },
  {
    path: "/EditeGestionOP/:OPid",
    name: "Nouvelle opération",
    icon: <IoSettings />,
    component: <EditeOperationManagement />,
    layout: "/admin",
    show: false,
    params: true
  },
  {
    path: "/gestionOP",
    name: "Gestion des opérations",
    icon: <IoSettings />,
    component: <OperationManagement />,
    layout: "/admin",
    show: true,
    subMenu: [
      {
        path: "/gestionOP",
        name: "Gestion des opérations",
        icon: <IoSettings />,
        component: <OperationManagement />,
        layout: "/admin",
        show: true,
      },
      {
        path: "/NewGestionOP",
        name: "Nouvelle opération",
        icon: <IoSettings />,
        component: <NewOperationManagement />,
        layout: "/admin",
        show: true
      }
    ]
  },
  {
    path: "/GestionDesPieces",
    name: "Gestion des pièces",
    icon: <IoSettings />,
    component: <ItemsManagement />,
    layout: "/admin",
    show: true
  },
  {
    path: "/NouvelleGestionDesPieces",
    name: "Gestion des pièces",
    icon: <IoSettings />,
    component: <NewItemsManagement />,
    layout: "/admin",
    show: false
  },
  {
    path: "/dataManagement",
    name: "Data",
    icon: <IoSettings />,
    component: <DataManagement />,
    layout: "/admin",
    show: true
  },
  {
    path: "/responsableTable",
    name: "Data",
    icon: <IoSettings />,
    component: <ResponsableTable />,
    layout: "/admin",
    show: false
  },
  {
    path: "/newResponsable",
    name: "Data",
    icon: <IoSettings />,
    component: <NewResponsable />,
    layout: "/admin",
    show: false
  },
  {
    path: "/floatTable",
    name: "Data",
    icon: <IoSettings />,
    component: <FloatTable />,
    layout: "/admin",
    show: false
  },
  {
    path: "/newFloat",
    name: "Data",
    icon: <IoSettings />,
    component: <NewFloat />,
    layout: "/admin",
    show: false
  },
  {
    path: "/documentTable",
    name: "Data",
    icon: <IoSettings />,
    component: <DocumentTable />,
    layout: "/admin",
    show: false
  },
  {
    path: "/newDocument",
    name: "Data",
    icon: <IoSettings />,
    component: <NewDocument />,
    layout: "/admin",
    show: false
  },
  {
    path: "/chargeVariable",
    name: "Data",
    icon: <IoSettings />,
    component: <ChargeVariable />,
    layout: "/admin",
    show: false
  },
  {
    path: "/evaluation",
    name: "Evaluation",
    icon: <IoSettings />,
    component: <Evaluation />,
    layout: "/admin",
    show: true
  },
  {
    path: "/index",
    name: "Support",
    icon: <IoSettings />,
    component: <Home />,
    layout: "/admin",
    show: true
  },
  {
    path: "/index",
    name: "Déconnexion",
    icon: <IoSettings />,
    component: <Home />,
    layout: "/admin",
    show: true
  },
];

export default routes;

