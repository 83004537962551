import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Button, Label } from "reactstrap";
import { Form, Select, DatePicker, Card } from "antd";

import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import dayjs from 'dayjs';

import MapComponent from "../../components/OperationManagement/MapComponent";

import { BsFillPatchPlusFill } from "react-icons/bs";

import SideBar from "../../components/SideBar";
import {
  listOfMatricul,
  listOfResponsable,
  getAllVehicul,
} from "../../api/data/Vehiculs";

import { getAllCityApi } from "../../api/city";
import { getGeoLocationApi } from "../../api/map";
import { useNavigate, useParams } from "react-router-dom";
import { editeOperationApi, getOneOperationApi } from "./../../api/operationManagemment"

import { socket } from './../../socket';

import Swal from 'sweetalert2';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

function EditeOperationManagement() {
  const navigate = useNavigate();
  const { OPid } = useParams()

  const position = [30.379245, -9.520939];

  // eslint-disable-next-line
  const [startPosition, setStartPosition] = useState({
    // lat: 33.036731,
    // lng: -7.484337,
  });
  // eslint-disable-next-line
  const [endPosition, setEndPosition] = useState({
    // lat: 30.410664,
    // lng: -9.584274,
  });

  const [cityData, setCityData] = useState([]);

  const [vehiculData, setVehiculData] = useState([]);
  const [responsableData, setResponsableData] = useState([]);
  const [matriculData, setMatriculData] = useState([]);

  // 2023/08/23 20:49:54
  // 2023/08/31 20:49:56

  const [userForm, setUserForm] = useState({
    startCityName: "",
    startCityLocation: {},
    arrivedCityName: "",
    arrivedCityLocation: {},

    selectedModel: "",
    selectedConducteur: "",
    selectedMatricule: "",

    SelectedFromDate: null,
    SelectedToDate: null,
  });

  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getAllInitialData();
        // Rest of your code that depends on the data
      } catch (error) {
        console.log('err', error)
      }
    };
  
    fetchData();
    // eslint-disable-next-line

    const onConnect = () => {
      console.log("Dashboard socket is connected")
      setIsConnected(true);
    }
    const onDisconnect = () => {
      console.log("Dashboard socket is disconnected")
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  const getAllInitialData = async () => {
    const cityRes = await getAllCityApi();
    setCityData(cityRes);

    const matriculDataRes = await listOfMatricul();
    setMatriculData(matriculDataRes);
    const responsableDataRes = await listOfResponsable();
    setResponsableData(responsableDataRes);
    const vehiculDataRes = await getAllVehicul();
    setVehiculData(vehiculDataRes);

    const thisOperationData = await getOneOperationApi(OPid)

    setUserForm({
      ...userForm,
      startCityName: thisOperationData.startCityName,
      arrivedCityName: thisOperationData.arrivedCityName,

      startCityLocation: thisOperationData.startCityLocation,
      arrivedCityLocation: thisOperationData.arrivedCityLocation,

      selectedModel: thisOperationData.selectedModel._id,
      selectedConducteur: thisOperationData.selectedConducteur._id,
      selectedMatricule: thisOperationData.selectedMatricule._id,
  
      SelectedFromDate: dayjs(thisOperationData.SelectedFromDate).format(dateFormat),
      SelectedToDate: dayjs(thisOperationData.SelectedToDate).format(dateFormat)
    })

    // console.log('From', dayjs(thisOperationData.SelectedFromDate).format(dateFormat))
    // console.log('To', dayjs(thisOperationData.SelectedToDate).format(dateFormat))

    setStartPosition({
      lat: thisOperationData.arrivedCityLocation.lat,
      lng: thisOperationData.arrivedCityLocation.lng,
    })
    setEndPosition({
      lat: thisOperationData.startCityLocation.lat,
      lng: thisOperationData.startCityLocation.lng,
    })
  };

  const handleDateChange = (selectedDate, selectedFormatedDate) => {
    if (selectedDate) {
      // const inputDate1 = new Date(selectedDate[0].$d);
      // const day = inputDate1.getDate().toString().padStart(2, "0");
      // const month = (inputDate1.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
      // const year = inputDate1.getFullYear();

      // const inputDate2 = new Date(selectedDate[1].$d);
      // const day2 = inputDate2.getDate().toString().padStart(2, "0");
      // const month2 = (inputDate2.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
      // const year2 = inputDate2.getFullYear();

      // const formattedDate1 = `${day}/${month}/${year}`;
      // const formattedDate2 = `${day2}/${month2}/${year2}`;

      setUserForm({
        ...userForm,
        SelectedFromDate: selectedFormatedDate[0],
        SelectedToDate: selectedFormatedDate[1]
      });
    }
  };

  const handleSelectInputChangeFrom = async (e) => {
    const selectedGeoLocation = await getGeoLocationApi(e);

    if(selectedGeoLocation.results[0]) {
      setStartPosition(selectedGeoLocation.results[0].geometry.location)
    }

    setUserForm({
      ...userForm,
      startCityName: e,
      startCityLocation:
        selectedGeoLocation.status === "OK"
          ? selectedGeoLocation.results[0].geometry.location
          : null,
    });
  };

  const handleSelectInputChangeTo = async (e) => {
    const selectedGeoLocation = await getGeoLocationApi(e);

    if(selectedGeoLocation.results[0]) {
      setEndPosition(selectedGeoLocation.results[0].geometry.location)
    }

    setUserForm({
      ...userForm,
      arrivedCityName: e,
      arrivedCityLocation:
        selectedGeoLocation.status === "OK"
          ? selectedGeoLocation.results[0].geometry.location
          : null,
    });
  };

  const handleSelectionChange = (value, name) => {
    if(name.key === "selectedModel") {
      const selectedVehicul = vehiculData.find(el => el._id === value)

      setUserForm({
        ...userForm,
        selectedConducteur: selectedVehicul.responsable._id,
        selectedMatricule: selectedVehicul.immatriculation._id,
        selectedModel: selectedVehicul._id
      })
    }
    else {
      setUserForm({ ...userForm, [name.key]: value})
    }
  }

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserForm({ ...userForm, [name]: value });
  // };

  const handleSubmitForm = async e => {
    e.preventDefault()
    try {
      const response = await editeOperationApi(userForm.startCityName, userForm.startCityLocation, userForm.arrivedCityName, userForm.arrivedCityLocation, userForm.selectedModel, userForm.selectedConducteur, userForm.selectedMatricule, userForm.SelectedFromDate, userForm.SelectedToDate, OPid)

      if(!response) {
        return Swal.fire({
            title: 'Erreur!',
            text: 'Server error',
            icon: 'error'
        });
      }

      Swal.fire({
          title: 'Modifiée',
          text: '',
          icon: 'success'
      });

      socket.emit('edite-direction-event');

      navigate("/admin/missionOrder/" + OPid);
    }
    catch(err) {
      Swal.fire({
        title: 'Erreur!',
        text: err.response.data.error,
        icon: 'error'
      });
    }
  };

  const handleReddirection = (value) => {
    navigate(`/admin/${value}`);
  };

  return (
    <Fragment>
      <section className="NewOperationManagementSection">
        <Row>
          <Col md="2" className="p-0">
            <SideBar />
          </Col>
          <Col md="10" className="p-0">
            <Row>
              <Col md="8">
                <div className="leftSideContents">
                  {/* {JSON.stringify(userForm)} */}
                  <Card title="" bordered={false}>
                    <div className="rowContents">
                      {/* <Form.Item name="select-multiple"> */}
                        <Select
                          placeholder="Ville de départ"
                          onChange={handleSelectInputChangeFrom}
                          value={userForm.startCityName}
                          name="startCityName"
                          style={{ width: '45%' }}
                        >
                          {cityData.map((el) => (
                            <Option key={el._id} value={el.ville}>{el.ville}</Option>
                          ))}
                        </Select>
                      {/* </Form.Item> */}

                      {/* <Form.Item name="select-multiple"> */}
                        <Select
                          placeholder="Ville d'arivée"
                          onChange={handleSelectInputChangeTo}
                          value={userForm.arrivedCityName}
                          name="arrivedCityName"
                          style={{ width: '45%' }}
                        >
                          {cityData.map((el) => (
                            <Option value={el.ville}>{el.ville}</Option>
                          ))}
                        </Select>
                      {/* </Form.Item> */}
                    </div>
                    <div className="rowContents">
                      <div className="w-80">
                        {/* <Form.Item name="select-multiple" style={{width: '100%'}}> */}
                          <Label>Choisir le Véhicule / Modèle</Label>
                          <Select placeholder="Choisir le Véhicule / Modèle" onChange={handleSelectionChange} value={userForm.selectedModel} id="selectedModelID">
                            {vehiculData.map((el) => (
                              <Option key="selectedModel" value={el._id}>{el.marque}</Option>
                            ))}
                          </Select>
                        {/* </Form.Item> */}
                      </div>
                      <div className="w-10 d-flex align-self-end">
                        <BsFillPatchPlusFill
                          size="25"
                          color="#1cbcb4"
                          onClick={() => handleReddirection("newFloat")}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="rowContents">
                      <div className="w-80">
                        {/* <Form.Item name="select-multiple" style={{width: '100%'}}> */}
                          <Label>Choisir le Conducteur</Label>
                          <Select placeholder="Choisir le Conducteur" onChange={handleSelectionChange} value={userForm.selectedConducteur}>
                            {responsableData.map((el) => (
                              <Option key="selectedConducteur" value={el._id}>{el.firstName} - {el.lastName}</Option>
                            ))}
                          </Select>
                        {/* </Form.Item> */}
                      </div>
                      <div className="w-10 d-flex align-self-end">
                        <BsFillPatchPlusFill
                          size="25"
                          color="#1cbcb4"
                          onClick={() => handleReddirection("newResponsable")}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="rowContents">
                      <div className="w-80">
                        {/* <Form.Item name="select-multiple" style={{width: '100%'}}> */}
                          <Label>Choisir le N° de matricule</Label>
                          <Select placeholder="Choisir le N° de matricule" onChange={handleSelectionChange} value={userForm.selectedMatricule}>
                            {matriculData.map((el) => (
                              <Option key="selectedMatricule" value={el._id}>{el.immatriculation}</Option>
                            ))}
                          </Select>
                        {/* </Form.Item> */}
                      </div>
                      <div className="w-10 d-flex align-self-end">
                        <BsFillPatchPlusFill
                          size="25"
                          color="#1cbcb4"
                          onClick={() => handleReddirection("newDocument")}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    <div className="rowContents">
                      <Form.Item name="range-picker">
                        {userForm.SelectedFromDate ? '' : ''}
                        <RangePicker
                          showTime={{ format: 'HH:mm:ss' }}
                          format={dateFormat}
                          value={[
                            userForm.SelectedFromDate ? dayjs(userForm.SelectedFromDate, dateFormat) : null,
                            userForm.SelectedToDate ? dayjs(userForm.SelectedToDate, dateFormat) : null,
                          ]}
                          defaultValue={[
                            userForm.SelectedFromDate ? dayjs(userForm.SelectedFromDate, dateFormat) : null,
                            userForm.SelectedToDate ? dayjs(userForm.SelectedToDate, dateFormat) : null,
                          ]}
                          onChange={handleDateChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="rowContents">
                      <Button className="submitBtn" onClick={handleSubmitForm}>
                        Modifier ordre de mission
                      </Button>
                    </div>
                  </Card>
                </div>
              </Col>
              <Col md="4">
                <div className="rightSideTabContent">
                  {/* <MapContainer
                    center={position}
                    zoom={13}
                    style={{ height: "100vh" }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <MapComponent
                      startPoint={startPosition}
                      endPoint={endPosition}
                    />
                  </MapContainer> */}

<MapContainer center={position} zoom={13} style={{ height: "100vh" }}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
                  <MapComponent
                    startPoint={startPosition}
                    endPoint={endPosition}
                  />
    </MapContainer>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
}

export default EditeOperationManagement;
