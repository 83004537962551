import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

import { AuthProvider } from './context/authContext';
import { HeaderProvider } from './context/headerContext';
// import AdminRoute from './components/Routes/AdminRoute';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import './assets/css/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <HeaderProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/auth/*" element={<AuthLayout />} />
              <Route path="*" element={<Navigate to="/admin/index" replace />} />
            </Routes>
          </BrowserRouter>
        </HeaderProvider>
      </AuthProvider>
    </React.StrictMode>
  );

reportWebVitals();
