import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Button } from "reactstrap";
import { Card } from "antd";

import SideBar from "../../components/SideBar";
import { useParams } from 'react-router-dom';

import { getOneOperationApi } from "../../api/operationManagemment"

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function MissionOrder() {
    const { missionOrderID } = useParams()

    const [initialData, setInitialData] = useState([])

    useEffect(() => {
        getInitialValue()
        // eslint-disable-next-line
    }, [])

    const getInitialValue = async () => {
        try {
            const response = await getOneOperationApi(missionOrderID)
            console.log(response)
            setInitialData(response)
        }
        catch(err) {
            console.log(err)
        }
    }

    const handleDownloadMissionOrder = async () => {
        const contentElement = document.querySelector('#JSPDF'); // Select the element to capture

        // Use html2canvas to capture the content as an image
        html2canvas(contentElement).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
    
          // Create a new jsPDF instance
          const pdf = new jsPDF('l', 'mm', 'a4');
    
          // Add the captured image to the PDF
          pdf.addImage(imgData, 'PNG', 0, 0, 300, 200); // Adjust width and height as needed
    
          // Save or download the PDF
          pdf.save('order de mission.pdf');
        });
    }

    return (
        <Fragment>
            <section className='missionOrder'>
                <Row>
                    <Col md="2" className="p-0">
                        <SideBar />
                    </Col>
                    <Col md="10" className="p-0">
                        <div className="contents">
                            <Row id='JSPDF'>
                                <Col md="4">
                                    <div className='righSideContents'>
                                        <p>Royaume du Maroc Ministère de l'intérieur</p>
                                        <img src={require('./../../assets/img/logo/logo.png')} alt="" className='logo' />
                                        <p>Wilaya de la Region Sous Massa Préfecture d'Agadir Idda Outanane</p>
                                    </div>
                                </Col>
                                <Col md="8">
                                    <div className='missionOrderContents'>
                                        <Card title="" bordered={false}>
                                            <h2>ORDRE DE MISSION</h2>
                                                <div className='contents'>
                                                    <div className="left">
                                                        <h3>{initialData.startCityName}</h3>
                                                        <small>{new Date(initialData.SelectedFromDate).toLocaleString()}</small>

                                                        <h4>Conducteur</h4>
                                                        <p>{initialData?.selectedConducteur?.firstName} {initialData?.selectedConducteur?.lastName}</p>
                                                        <h4>Véhicule / Model</h4>
                                                        <p>{initialData?.selectedModel?.marque}</p>
                                                        <h4>Véhicule / immatriculation</h4>
                                                        <p>{initialData?.selectedMatricule?.immatriculation}</p>
                                                        <h4>Date</h4>
                                                        <div className='dateMission'>
                                                            <p>{new Date(initialData?.SelectedFromDate).toLocaleString()}</p>
                                                            <p>{new Date(initialData?.SelectedToDate).toLocaleString()}</p>
                                                        </div>

                                                        <h3>{initialData.arrivedCityName}</h3>
                                                        <small>{new Date(initialData.SelectedToDate).toLocaleString()}</small>
                                                    </div>
                                                    <div className="right">
                                                        <h3>Model</h3>
                                                        <small>{initialData?.selectedModel?.modele}</small>

                                                        <h3>Conducteur</h3>
                                                        <small>{initialData?.selectedConducteur?.firstName} {initialData?.selectedConducteur?.lastName}</small>

                                                        <h4>{initialData?.selectedConducteur?.phone}</h4>
                                                    </div>
                                                </div>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className='bottomBtn'>
                                    <Button onClick={handleDownloadMissionOrder}>TELECHARGER ORDRE DE MISSION</Button>
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default MissionOrder
