import React, { Fragment } from "react";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import routes from "./../routes";
import { useNavigate } from "react-router-dom";

function SideBar() {
    const navigate = useNavigate();

    const handleRedirection = (value) => {
        navigate(value)
    }

    const getRoutes = (routes) => {
        // eslint-disable-next-line
        return routes.filter(el => el.show).map((prop, key) => {
            if(prop.subMenu && prop.subMenu[0]) {
                return (
                    <SubMenu icon={prop.icon} label={prop.name}>
                        {
                            prop.subMenu.filter(el => el.show).map((subMenuItems, key) => (
                                <MenuItem icon={subMenuItems.icon} onClick={() => handleRedirection(subMenuItems.layout + subMenuItems.path)}> {subMenuItems.name} </MenuItem>
                            ))
                        }
                    </SubMenu>
                )
            }
            else {
                return (
                    <MenuItem icon={prop.icon} onClick={() => handleRedirection(prop.layout + prop.path)}> {prop.name} </MenuItem>
                )
            }
        })
    }
  return (
    <Fragment>
      <section className="sideBarExemple">
        <div className="contents">
          <div style={{ display: "flex", height: "100vh", width: "100%" }}>
            <Sidebar style={{ width: "100%", minWidth: "100%" }}>
              <Menu>
                <Menu>
                    {getRoutes(routes)}
                </Menu>
              </Menu>
            </Sidebar>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default SideBar;
