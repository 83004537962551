import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.25 84.54" width={size} height={size}>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <path class="cls-1" fill={fill} d="M33.15,74.33h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-1" fill={fill} d="M106.74,74.33h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-1" fill={fill} d="M33.15,74.33c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-1" fill={fill} d="M33.15,74.33c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-1" fill={fill} d="M106.74,74.33c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <path class="cls-1" fill={fill} d="M106.74,74.33c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <rect class="cls-1" fill={fill} x="34.49" y="75.38" width="52.34" height="2.55"/>
            <path class="cls-1" fill={fill} d="M113.27,57.91c-1.85-1.85-4.42-2.57-7.21-2.99-2.34-.36-7.54-1.44-8.6-2.09l-3-3.86c-3.59-5.51-8.27-10.21-13.75-13.84l-.35-.23c-1.9-1.26-4.14-1.93-6.42-1.93H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM3.38,68.2h0c-.46,0-.83-.37-.83-.83v-16.72c0-.46,.37-.83,.83-.83h0c.46,0,.83,.37,.83,.83v16.72c0,.46-.37,.83-.83,.83Zm66.83-18.38v-12.39h8.78c1.63,0,3.17,.75,4.17,2.03l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.59,4.2c.21,.34-.03,.77-.43,.77h-18.89Z"/>
          </g>
          <g>
            <rect class="cls-2" fill={fill} x="86.43" y="14.29" width="23.01" height="2.02"/>
            <rect class="cls-2" fill={fill} x="86.43" y="20.67" width="23.01" height="2.02"/>
            <rect class="cls-2" fill={fill} x="86.43" y="27.06" width="23.01" height="2.02"/>
            <rect class="cls-2" fill={fill} x="98.61" y="33.44" width="10.82" height="2.02"/>
          </g>
          <path class="cls-2" fill={fill} d="M107.25,0h-27.53c-.42,0-.76,.34-.76,.76V30.87h2V2.76c0-.42,.34-.76,.76-.76h23.68V11.36h8.56V48.06c0,.42-.34,.76-.76,.76h-15.68v2h17.68c.42,0,.76-.34,.76-.76V8.71L107.25,0Zm.15,2.97l6.39,6.39h-6.39V2.97Z"/>
        </g>
      </g>
    </svg>
  );
}

export default Assurances;
