import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.93 79.66" width={size} height={size}>
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <path class="cls-1" fill={fill} d="M116.69,19.37c-.86-.01-1.72-.01-2.58,0-.33,0-.5-.12-.59-.47-.47-1.91-1.22-3.7-2.24-5.39-.17-.28-.14-.47,.09-.69,.63-.61,1.25-1.23,1.86-1.86,.48-.48,.48-.87,0-1.34-1.71-1.72-3.42-3.44-5.15-5.15-.48-.48-.85-.48-1.34,0-.62,.61-1.25,1.22-1.85,1.86-.23,.23-.42,.25-.7,.08-1.69-1.01-3.48-1.76-5.39-2.23-.34-.09-.47-.27-.47-.6,.01-.86,.01-1.71,0-2.58-.01-.73-.27-.99-1.01-.99-1.04-.01-2.08-.01-3.12-.01h-.48c-1.04,0-2.07,0-3.11,.01-.75,0-1.01,.26-1.02,.99-.01,.87-.01,1.72,0,2.58,0,.33-.12,.51-.47,.6-1.91,.47-3.7,1.22-5.38,2.23-.28,.17-.47,.15-.71-.08-.6-.64-1.23-1.25-1.85-1.86-.48-.48-.86-.48-1.34,0-1.73,1.71-3.44,3.43-5.15,5.15-.49,.47-.49,.86-.01,1.34,.61,.63,1.23,1.25,1.86,1.86,.24,.22,.27,.41,.1,.69-1.03,1.69-1.78,3.48-2.25,5.39-.08,.35-.25,.47-.59,.47-.85-.01-1.72-.01-2.57,0-.74,.01-1,.27-1.01,1.01v3.18c0,1.34-.01,2.66,0,4,0,.22,.03,.4,.09,.53,.12,.32,.4,.44,.9,.44,.85,0,1.72,.01,2.57,0,.33-.01,.51,.09,.6,.45,.32,1.23,.73,2.42,1.27,3.57h3.35c1.63,0,3.16,.75,4.17,2.03l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.35,3.81c.2,.1,.28,.26,.29,.5,.01,.03,.01,.07,.01,.11v.19c-.02,.78-.01,1.55,0,2.34,.01,.73,.21,.93,.94,.93h6.86c.74,0,.93-.2,.94-.93,.01-.85,.02-1.69,0-2.53,0-.38,.12-.58,.51-.68,1.83-.44,3.58-1.15,5.19-2.12l-3.9-4.91c-1.86,.9-3.96,1.41-6.17,1.41-1.74,0-3.4-.31-4.94-.89-3.17-1.17-5.8-3.45-7.43-6.36-1.14-2.04-1.8-4.4-1.8-6.92,0-7.82,6.35-14.17,14.17-14.17s14.17,6.35,14.17,14.17c0,1.97-.4,3.84-1.13,5.55l4.81,4.01c.76-1.43,1.33-2.93,1.73-4.5,.1-.36,.27-.46,.6-.45,.86,.01,1.72,0,2.58,0,.71,0,.97-.25,.98-.97,.01-1.34,0-2.66,0-4v-3.18c-.01-.74-.26-1-1-1.01Z"/>
      <g>
        <path class="cls-1" fill={fill} d="M100.9,18.64l-3.45-3.32c-.61-.59-1.44-.91-2.3-.9l-5.62,.12,8.08,7.53,3.3-3.43Z"/>
        <path class="cls-1" fill={fill} d="M101.46,19.18l-3.29,3.42h0s-2.72,2.84-2.72,2.84l-2.72,2.83-8.4-8.32,.1,5.62c.02,.86,.37,1.68,.99,2.26l5.36,5.15c.67,.64,1.56,.99,2.48,.97l5.3-.1,23.06,23.94c1.31,1.36,3.49,1.36,4.8,0l1.29-1.34,1.29-1.34c1.31-1.36,1.22-3.54-.19-4.8l-24.83-22.09-.1-5.3c-.02-.92-.4-1.8-1.07-2.44l-1.35-1.3Zm24.22,35.32c-1.17,1.22-3.22,1.15-4.57-.15s-1.51-3.35-.34-4.57,3.22-1.15,4.58,.16,1.5,3.34,.33,4.56Z"/>
      </g>
      <g>
        <path class="cls-2" fill={fill} d="M33.15,69.45h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
        <path class="cls-2" fill={fill} d="M106.74,69.45h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
        <path class="cls-2" fill={fill} d="M33.15,69.45c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
        <path class="cls-2" fill={fill} d="M33.15,69.45c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
        <path class="cls-2" fill={fill} d="M106.74,69.45c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
        <path class="cls-2" fill={fill} d="M106.74,69.45c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
        <rect class="cls-2" fill={fill} x="34.49" y="70.5" width="52.34" height="2.55"/>
        <path class="cls-2" fill={fill} d="M113.27,53.03c-1.85-1.85-4.42-2.57-7.21-2.99-2.35-.36-7.54-1.44-8.6-2.09l-.08-.1-2.92-3.76c-1.61-2.46-3.43-4.77-5.45-6.89-2.25-2.36-4.74-4.49-7.43-6.36-.29-.2-.58-.4-.87-.59l-.35-.23c-1.9-1.26-4.14-1.93-6.42-1.93H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM4.21,62.49c0,.46-.37,.83-.83,.83s-.83-.37-.83-.83v-16.72c0-.46,.37-.83,.83-.83s.83,.37,.83,.83v16.72Zm84.88-17.55h-18.88v-12.39h8.78c1.63,0,3.16,.75,4.17,2.03l.16,.21c1.3,1.66,2.51,3.39,3.61,5.18l2.35,3.81,.24,.39s.04,.08,.05,.11c.04,.1,.03,.2,.01,.3-.06,.2-.24,.36-.49,.36Z"/>
      </g>
    </g>
  </g>
</svg>
  );
}

export default Assurances;
