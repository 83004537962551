import API_URL from "../../config";
import axios from "axios";

export const addDocument = async (immatriculation, cartGris, vignette, assurance, visiteTechnique) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const RhObject = {
            immatriculation: immatriculation,
            cartGris: cartGris,
            vignette: vignette,
            assurance: assurance,
            visiteTechnique: visiteTechnique
        };

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/documents/add/${id}`, RhObject, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getOneDocument = async (DocumentID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/documents/getOne/${id}/${DocumentID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllDocument = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/documents/getAll/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const updateDocumentStatus = async (DocumentID, DocumentStatus) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/documents/updateOneStatus/${id}/${DocumentID}`, {status: DocumentStatus}, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const deleteOneDocument = async (DocumentID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/documents/delete/${id}/${DocumentID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}
