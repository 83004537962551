import API_URL from "../../config";
import axios from "axios";

export const addRH = async (firstName, lastName, grade, fonction, cin, ppr, phone, userName, email, password, confirmPass, permisNumber) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        // if(!token || !id)
        //     return false

        const RhObject = {
            firstName: firstName,
            lastName: lastName,
            grade: grade,
            fonction: fonction,
            cin: cin,
            ppr: ppr,
            phone: phone,
            userName: userName,
            email: email,
            password: password,
            confirmPass: confirmPass,
            permisNumber: permisNumber
        };

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/humanRessources/addHumanRessource/${id}`, RhObject, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getOneRH = async (RhID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/humanRessources/getOne/${id}/${RhID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const getAllRH = async () => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/humanRessources/getAll/${id}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const updateRHStatus = async (RhID, RhStatus) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/humanRessources/updateOneStatus/${id}/${RhID}`, {status: RhStatus}, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}

export const deleteOneRH = async (RhID) => {
    try {
        const token = sessionStorage.getItem("token")
        const id = sessionStorage.getItem("id")

        if(!token || !id)
            return false

        const option = {
            headers: {
              Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/humanRessources/deleteOne/${id}/${RhID}`, option)
        return res.data
    }
    catch(err) {
        throw err
    }
}
