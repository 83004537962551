import React, { Fragment, useEffect, useState } from 'react'
import { Chart } from "react-google-charts";

function BarChart() {
    const [chartData, setChartData] = useState([])
    const [chartOption, setChartOption] = useState({})

    const handleChartData = () => {
        setChartData([
            ["City", "2010 Population", "2000 Population"],
            ["New York City, NY", 8175000, 8008000],
            ["Los Angeles, CA", 3792000, 3694000],
            ["Chicago, IL", 2695000, 2896000],
            ["Houston, TX", 2099000, 1953000],
            ["Philadelphia, PA", 1526000, 1517000],
        ])
    }

    const handleChartOption = () => {
        setChartOption({
            title: "Population of Largest U.S. Cities",
            chartArea: { width: "50%" },
            hAxis: {
              title: "Total Population",
              minValue: 0,
            },
            vAxis: {
              title: "City",
            },
        })
    }

    useEffect(() => {
        handleChartData()
        handleChartOption()
    }, [])
  return (
    <Fragment>
        <section>
            <Chart
                chartType="BarChart"
                width="100%"
                height="400px"
                data={chartData}
                options={chartOption}
            />
        </section>
    </Fragment>
  )
}

export default BarChart
