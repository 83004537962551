import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

function Header() {
  return (
    <section className='headerComponent'>
        <div className="contents">
            <div className="rowContents">
                <p>Gestion des pièces</p>
                <Link to="/admin/NouvelleGestionDesPieces">
                    <Button className='submitButton'> Ajouter un article </Button>
                </Link>
            </div>
        </div>
    </section>
  )
}

export default Header
