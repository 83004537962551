import React from "react";

function Assurances(props) {
  const size = props.size || "100%";
  const fill = props.color || "none";

  return (
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.66 101.48" width={size} height={size}>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <path class="cls-1" fill={fill} d="M33.15,91.27h-5.3c-.41-1.82-2.04-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-1" fill={fill} d="M106.74,91.27h-5.3c-.4-1.82-2.03-3.19-3.98-3.19s-3.58,1.37-3.99,3.19h-5.29c.45-4.72,4.43-8.43,9.28-8.43s8.83,3.71,9.28,8.43Z"/>
            <path class="cls-1" fill={fill} d="M33.15,91.27c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-1" fill={fill} d="M33.15,91.27c-.45-4.72-4.44-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3-.01-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.57,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.83,4.09-4.08,4.09Z"/>
            <path class="cls-1" fill={fill} d="M106.74,91.27c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <path class="cls-1" fill={fill} d="M106.74,91.27c-.45-4.72-4.43-8.43-9.28-8.43s-8.83,3.71-9.28,8.43c-.03,.29-.04,.59-.04,.89,0,5.15,4.17,9.32,9.32,9.32s9.32-4.17,9.32-9.32c0-.3,0-.6-.04-.89Zm-9.28,4.98c-2.26,0-4.09-1.83-4.09-4.09,0-.31,.03-.6,.1-.89,.41-1.82,2.04-3.19,3.99-3.19s3.58,1.37,3.98,3.19c.07,.29,.1,.58,.1,.89,0,2.26-1.82,4.09-4.08,4.09Z"/>
            <rect class="cls-1" fill={fill} x="34.49" y="92.32" width="52.34" height="2.55"/>
            <path class="cls-1" fill={fill} d="M113.27,74.85c-1.85-1.85-4.43-2.57-7.21-2.99-2.35-.36-7.54-1.44-8.6-2.09l-3-3.86c-.52-.8-1.06-1.58-1.63-2.34-.87-1.18-1.79-2.31-2.76-3.39-2.75-3.09-5.89-5.81-9.36-8.11l-.35-.23c-1.9-1.26-4.14-1.93-6.42-1.93H7.21c-2.42,0-4.52,1.68-5.04,4.05l-.6,2.71c-1.05,4.78-1.57,9.66-1.57,14.56v20.04H13.06c.45-5.57,5.11-9.96,10.81-9.96s10.36,4.39,10.81,9.96h51.97c.45-5.57,5.12-9.96,10.81-9.96s10.36,4.39,10.81,9.96h7.98v-9.22c0-2.81-1.14-5.36-2.98-7.2ZM4.21,84.31c0,.46-.38,.83-.83,.83s-.83-.37-.83-.83v-16.72c0-.46,.37-.83,.83-.83s.83,.37,.83,.83v16.72Zm84.88-17.55h-18.88v-12.39h8.77c1.63,0,3.17,.75,4.17,2.03l.17,.21c1.08,1.37,2.09,2.79,3.02,4.26,.21,.3,.4,.61,.59,.92l.49,.8,2.1,3.4c.21,.34-.03,.77-.43,.77Z"/>
          </g>
          <g>
            <polygon class="cls-2" fill="#bbcbd5" points="72.98 40.14 84.36 35.5 84.36 38.2 72.98 42.84 72.98 40.14"/>
            <polygon class="cls-2" fill="#bbcbd5" points="104 40.09 104 42.83 89.86 36.63 89.86 33.9 104 40.09"/>
            <polygon class="cls-2" fill="#bbcbd5" points="117.54 35.44 117.54 38.14 106 42.84 106 40.14 117.54 35.44"/>
          </g>
          <polygon class="cls-1" fill={fill} points="89.86 10.12 89.86 52.24 84.36 52.24 84.36 13.88 72.98 18.53 72.98 45.35 70.98 45.35 70.98 17.19 84.36 11.73 89.1 9.79 89.86 10.12"/>
          <path class="cls-1" fill={fill} d="M137.02,42.54v23.74l-13.98-6.13V10.2l-.93-.41-4.57,1.87-12.52,5.11-10.07-4.41v2.19l9.05,3.96v47.78l-13.93-6.11c.97,1.08,1.89,2.21,2.76,3.39l12.15,5.33,12.56-5.12,4.55-1.86,.95,.42,15.98,7v-26.8h-2Zm-19.48,19.08l-11.54,4.71V18.53l11.54-4.71V61.62Z"/>
          <g>
            <circle class="cls-3" fill="none" stroke={fill} strokeMiterlimit="10" strokeWidth="2px" cx="137.85" cy="10.73" r="2.98"/>
            <path class="cls-4" fill="none" stroke={fill} strokeMiterlimit="10" d="M146.1,4.36c4.12,3.99,2.76,10.21-.88,14.63-.06,.07-.11,.14-.17,.21-2.04,2.5-3.98,5.09-5.76,7.78l-1.44,2.18-1.48-2.24c-1.81-2.74-3.78-5.36-5.86-7.9-.01-.01-.02-.03-.03-.04-3.64-4.43-5-10.64-.88-14.63,2.28-2.21,5.27-3.33,8.26-3.33s5.97,1.11,8.25,3.33Z"/>
            <line class="cls-4" fill="none" stroke={fill} strokeMiterlimit="10" x1="137.85" y1="29.17" x2="137.85" y2="35.5"/>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Assurances;
