import React, { useEffect, useState } from "react";
import { Col, Row, Table, Button } from "reactstrap";
import { AiFillDelete } from "react-icons/ai";

import SideBar from "../../components/SideBar";
import Header from "../../components/DataManagement/Float/Header";

import {
  getAllVehicul,
  updateVehiculStatus,
  deleteOneVehicul,
} from "./../../api/data/Vehiculs";

import Swal from "sweetalert2";

function FloatTable() {
  const [FlottData, setFlottData] = useState([]);

  const getFlotteData = async () => {
    try {
      const response = await getAllVehicul();
      setFlottData(response);

      console.log(response)
    } catch (err) {
      console.log(err);
    }
  };

  const handleStateChange = async (id, status) => {
    try {
      Swal.fire({
        title:
          status === "V"
            ? "Voulez vous valider cette operation"
            : "Voulez vous annuler cette operation",
        icon: "warning",
        confirmButtonColor: status === "V" ? "#198754" : "#dc3545",
        confirmButtonText: status === "V" ? "Valider" : "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await updateVehiculStatus(id, status);
            if (response) {
              getFlotteData();
              Swal.fire(status === "V" ? "Validée" : "Annulée", "", "success");
            }
          } catch (err) {
            Swal.fire("Erreur", "", "error");
          }

          //   Swal.fire(status === 'V' ? "Validée" : "Annulée", "", "success");
        }
      });
    } catch (err) {}
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "Voulez vous suprimer cette operation",
        icon: "warning",
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Supprimer",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await deleteOneVehicul(id);
            if (response) {
              getFlotteData();
              Swal.fire("Suprimée", "", "success");
            }
          } catch (err) {
            Swal.fire("Erreur", "", "error");
          }

          // Swal.fire("Suprimée", "", "success");
        }
      });
    } catch (err) {}
  };

  useEffect(() => {
    getFlotteData();
  }, []);

  return (
    <section className="floatTableSection">
      <div className="contents">
        <Row>
          <Col md="2" className="p-0">
            <SideBar />
          </Col>
          <Col md="10" className="p-0">
            <Header />

            <div className="table">
              <Table striped>
                <thead>
                  <tr>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Date</th>
                    <th>Immatriculation</th>
                    <th>Responsable</th>
                    <th>Affectation ou carte grise</th>
                    <th>Affectation préfectorale</th>
                    <th>Etat d'opération</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {FlottData.map((el) => (
                    <tr>
                      <td>{el.marque}</td>
                      <td>{el.modele}</td>
                      <td>{(el.miseEnCirculation).substr(0, 10)}</td>
                      <td>{el.immatriculation.immatriculation}</td>
                      <td>{el.responsable.firstName} <br /> {el.responsable.lastName}</td>
                      <td>{el.cartGris}</td>
                      <td>{el.prefectorale}</td>
                      <td>
                        {el.status === "N" && (
                          <div className="btnStatus">
                            <Button
                              size="sm"
                              color="success"
                              onClick={() => handleStateChange(el._id, "V")}
                            >
                              Valider
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => handleStateChange(el._id, "A")}
                            >
                              Annuler
                            </Button>
                          </div>
                        )}

                        {el.status === "V" && (
                          <div className="btnStatus">
                            <Button size="sm" color="success" disabled>
                              Validée
                            </Button>
                          </div>
                        )}

                        {el.status === "A" && (
                          <div className="btnStatus">
                            <Button size="sm" color="danger" disabled>
                              Annulée
                            </Button>
                          </div>
                        )}
                      </td>
                      <td>
                        <AiFillDelete
                          size="1.5rem"
                          color="red"
                          onClick={() => handleDelete(el._id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default FloatTable;
