import React, { useState, useEffect } from "react";

import { Row, Col } from "reactstrap";
import { AutoComplete, Select, Card } from "antd";

import SideBar from "../../components/SideBar";
import Header from "../../components/Evaluation/Header";

import BarChart from '../../components/Evaluation/Bar'
import LineChart from '../../components/Evaluation/LineChart'

import {
  listOfMatricul,
  listOfResponsable,
  getAllVehicul,
} from "../../api/data/Vehiculs";

import { getEvaluationData } from "../../api/evaluation"

const { Option } = Select;

function Evaluation() {
  const [selectedEvaluation, setSelectedEvalution] = useState(null)

  const [vehiculData, setVehiculData] = useState([]);
  const [responsableData, setResponsableData] = useState([]);
  const [matriculData, setMatriculData] = useState([]);

  const [selectionValue, setSelectionValue] = useState({
    selectedModel: "",
    selectedConducteur: "",
    selectedMatricule: "",
  })

  const [evaluationData, setEvaluationData] = useState([])

  useEffect(() => {
    getAllInitialData();
  }, []);

  const getAllInitialData = async () => {
    // const matriculSelectData = matriculDataRes.reduce((accumulator, currentValue) => {
    //   accumulator['label'] = currentValue.immatriculation;
    //   accumulator['value'] = currentValue._id;
    //   return accumulator;
    // }, {})

    const matriculDataRes = await listOfMatricul();
    setMatriculData(matriculDataRes);
    const responsableDataRes = await listOfResponsable();
    setResponsableData(responsableDataRes);
    const vehiculDataRes = await getAllVehicul();
    setVehiculData(vehiculDataRes);
  };

  const handleSelectedEvaluationChange = async (value) => {
    const evaluationDataRes = await getEvaluationData(value, 2023)
    setEvaluationData(evaluationDataRes)

    setSelectedEvalution(value);
  };

  const handleModeleSelectionChange = (value) => {
    const selectedVehicul = vehiculData.find(el => el._id === value)
    setSelectionValue({
      ...selectionValue,
      selectedModel: value,
      selectedMatricule: selectedVehicul.immatriculation._id
    })
  }
  const handleMatriculSelectionChange = (value) => {
    setSelectionValue({ ...selectionValue, selectedMatricule: value})
  }
  const handleOperationSelectionChange = (value) => {
    setSelectionValue({ ...selectionValue, selectedConducteur: value})
  }

  const options = [
    {
      value: "A",
      label: "Burns Bay Road"
    },
    {
      value: "B",
      label: "Downing Street"
    },
    {
      value: "C",
      label: "Wall Street"
    },
  ];

  const evaluationOption = [
    {
      value: "Entretien",
      label: "L'entretien-réparations",
    },
    {
      value: "Carburant",
      label: "Carburant",
    },
    {
        value: "Pneumatiques",
        label: "Pneumatique",
    },
    {
        value: "Peages",
        label: "Péage",
    },
    {
        value: "Deplacement",
        label: "Indemnités de déplacement",
    },
    {
        value: "AutreCout",
        label: "Aûtre cout a inclure dans la recomposition annuelle",
    }
  ];

  return (
    <section className="evaluationSection">
      <div className="contents">
        <Row>
          <Col md="2" className="p-0">
            <SideBar />
          </Col>
          <Col md="10" className="p-0">
            <Header />

            <div className="topInputs">
              {/* <div className="inputs">
                <label htmlFor="selectedModelID">Véhicule / Modèle</label>
                <Select
                  placeholder="Choisir le Véhicule / Modèle"
                  onChange={handleModeleSelectionChange}
                  value={selectionValue.selectedModel}
                  id="selectedModelID"
                  showSearch
                  filterOption={(input, option) =>
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                >
                  {vehiculData.map((el) => (
                    <Option key={el.marque +' - '+ el.modele} value={el._id}>{el.marque} - {el.modele}</Option>
                  ))}
                </Select>
              </div>
              <div className="inputs">
                <label htmlFor="selectedMatriculID">Véhicule / Immatriculation</label>
                <Select
                  placeholder="Choisir N° matricule"
                  onChange={handleMatriculSelectionChange}
                  value={selectionValue.selectedMatricule}
                  id="selectedMatriculID"
                  showSearch
                  filterOption={(input, option) =>
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                >
                  {matriculData.map((el) => (
                    <Option key={el.immatriculation} value={el._id}>{el.immatriculation}</Option>
                  ))}
                </Select>
              </div> */}
              {/* <div className="inputs">
                <label htmlFor="">Véhicule / Opération</label>
                <AutoComplete
                  style={{ width: 200 }}
                  options={options}
                  placeholder="Catégorie d'opération"
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </div> */}
              <div className="inputs">
                <label htmlFor="">Evaluation</label>
                <Select
                  style={{
                    width: "100%",
                  }}
                  onChange={handleSelectedEvaluationChange}
                  options={evaluationOption}
                />
              </div>
            </div>

            {/* Entretien */}
            {
              selectedEvaluation === "Entretien" &&
                <div className="evaluationSectionContents">
                  <Row>
                      <Col md="5">
                          <div className="leftContents">
                            <h3>L'entretien-réparations</h3>
                            <Card title="" bordered={false} className="cardContents">
                              <p><span>Budget aloué</span> <span>:</span> <span>{evaluationData.allowedBudget}</span> <span>DH</span> </p>
                              <p><span>Budget consommé</span> <span>:</span> <span>{evaluationData.consomedBudget}</span > <span>DH</span> </p>
                              <p><span>Budget resté</span> <span>:</span> <span>{evaluationData.restBudget}</span> <span>DH</span> </p>

                              <p><span>Cout / mois consomé</span><span>:</span><span>{evaluationData.averageConsomedBudgetByMonth}</span><span>DH</span></p>
                              <p><span>Cout / mois reste</span><span>:</span><span>{evaluationData.averageRestBudgetBudgetByMonth}</span><span>DH</span></p>
                            </Card>
                          </div>
                      </Col>
                      <Col md="7">
                          <div className="chart">
                            <LineChart dataByMonth={evaluationData.dataByMonth} />
                          </div>
                      </Col>
                  </Row>
                </div>
            }

            {/* Carburant */}
            {
              selectedEvaluation === "Carburant" &&
                <div className="evaluationSectionContents">
                  <Row>
                      <Col md="5">
                          <div className="leftContents">
                            <h3>Carburant</h3>
                            <Card title="" bordered={false} className="cardContents">
                              <p><span>Budget aloué</span> <span>:</span> <span>{evaluationData.allowedBudget}</span> <span>DH</span> </p>
                              <p><span>Budget consommé</span> <span>:</span> <span>{evaluationData.consomedBudget}</span > <span>DH</span> </p>
                              <p><span>Budget resté</span> <span>:</span> <span>{evaluationData.restBudget}</span> <span>DH</span> </p>

                              <p><span>Cout / mois consomé</span><span>:</span><span>{evaluationData.averageConsomedBudgetByMonth}</span><span>DH</span></p>
                              <p><span>Cout / mois reste</span><span>:</span><span>{evaluationData.averageRestBudgetBudgetByMonth}</span><span>DH</span></p>
                            </Card>
                          </div>
                      </Col>
                      <Col md="7">
                          <div className="chart">
                            <LineChart dataByMonth={evaluationData.dataByMonth} />
                          </div>
                      </Col>
                  </Row>
                </div>
            }

            {/* Pneumatiques */}
            {
              selectedEvaluation === "Pneumatiques" &&
                <div className="evaluationSectionContents">
                  <Row>
                      <Col md="5">
                          <div className="leftContents">
                            <h3>Pneumatiques</h3>
                            <Card title="" bordered={false} className="cardContents">
                              <p><span>Budget aloué</span> <span>:</span> <span>{evaluationData.allowedBudget}</span> <span>DH</span> </p>
                              <p><span>Budget consommé</span> <span>:</span> <span>{evaluationData.consomedBudget}</span > <span>DH</span> </p>
                              <p><span>Budget resté</span> <span>:</span> <span>{evaluationData.restBudget}</span> <span>DH</span> </p>

                              <p><span>Cout / mois consomé</span><span>:</span><span>{evaluationData.averageConsomedBudgetByMonth}</span><span>DH</span></p>
                              <p><span>Cout / mois reste</span><span>:</span><span>{evaluationData.averageRestBudgetBudgetByMonth}</span><span>DH</span></p>
                            </Card>
                          </div>
                      </Col>
                      <Col md="7">
                          <div className="chart">
                            <LineChart dataByMonth={evaluationData.dataByMonth} />
                          </div>
                      </Col>
                  </Row>
                </div>
            }

            {/* Peages */}
            {
              selectedEvaluation === "Peages" &&
                <div className="evaluationSectionContents">
                  <Row>
                      <Col md="5">
                          <div className="leftContents">
                            <h3>Péages</h3>
                            <Card title="" bordered={false} className="cardContents">
                              <p><span>Budget aloué</span> <span>:</span> <span>{evaluationData.allowedBudget}</span> <span>DH</span> </p>
                              <p><span>Budget consommé</span> <span>:</span> <span>{evaluationData.consomedBudget}</span > <span>DH</span> </p>
                              <p><span>Budget resté</span> <span>:</span> <span>{evaluationData.restBudget}</span> <span>DH</span> </p>

                              <p><span>Cout / mois consomé</span><span>:</span><span>{evaluationData.averageConsomedBudgetByMonth}</span><span>DH</span></p>
                              <p><span>Cout / mois reste</span><span>:</span><span>{evaluationData.averageRestBudgetBudgetByMonth}</span><span>DH</span></p>
                            </Card>
                          </div>
                      </Col>
                      <Col md="7">
                          <div className="chart">
                            <LineChart dataByMonth={evaluationData.dataByMonth} />
                          </div>
                      </Col>
                  </Row>
                </div>
            }

            {/* Deplacement */}
            {
              selectedEvaluation === "Deplacement" &&
                <div className="evaluationSectionContents">
                  <Row>
                      <Col md="5">
                          <div className="leftContents">
                            <h3>Indemnités de déplacement</h3>
                            <Card title="" bordered={false} className="cardContents">
                              <p><span>Budget aloué</span> <span>:</span> <span>{evaluationData.allowedBudget}</span> <span>DH</span> </p>
                              <p><span>Budget consommé</span> <span>:</span> <span>{evaluationData.consomedBudget}</span > <span>DH</span> </p>
                              <p><span>Budget resté</span> <span>:</span> <span>{evaluationData.restBudget}</span> <span>DH</span> </p>

                              <p><span>Cout / mois consomé</span><span>:</span><span>{evaluationData.averageConsomedBudgetByMonth}</span><span>DH</span></p>
                              <p><span>Cout / mois reste</span><span>:</span><span>{evaluationData.averageRestBudgetBudgetByMonth}</span><span>DH</span></p>
                            </Card>
                          </div>
                      </Col>
                      <Col md="7">
                          <div className="chart">
                            <LineChart dataByMonth={evaluationData.dataByMonth} />
                          </div>
                      </Col>
                  </Row>
                </div>
            }

            {/* AutreCout */}
            {
              selectedEvaluation === "AutreCout" &&
                <div className="evaluationSectionContents">
                  <Row>
                      <Col md="5">
                          <div className="leftContents">
                            <h3>Aûtre cout a inclure dans la recomposition annuelle</h3>
                            <Card title="" bordered={false} className="cardContents">
                              <p><span>Budget aloué</span> <span>:</span> <span>{evaluationData.allowedBudget}</span> <span>DH</span> </p>
                              <p><span>Budget consommé</span> <span>:</span> <span>{evaluationData.consomedBudget}</span > <span>DH</span> </p>
                              <p><span>Budget resté</span> <span>:</span> <span>{evaluationData.restBudget}</span> <span>DH</span> </p>

                              <p><span>Coût / mois consommé</span><span>:</span><span>{evaluationData.averageConsomedBudgetByMonth}</span><span>DH</span></p>
                              <p><span>Coût / mois reste</span><span>:</span><span>{evaluationData.averageRestBudgetBudgetByMonth}</span><span>DH</span></p>
                            </Card>
                          </div>
                      </Col>
                      <Col md="7">
                          <div className="chart">
                            <LineChart dataByMonth={evaluationData.dataByMonth} />
                          </div>
                      </Col>
                  </Row>
                </div>
            }
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default Evaluation;
